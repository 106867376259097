import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Formik } from 'formik'
import toast from 'react-hot-toast'
import {  Divider, Grid, IconButton, Tooltip, Typography, useMediaQuery, useTheme, withStyles } from '@material-ui/core'
import { colors } from '../../Themes'
import { 
  AccountAvatarInfo,
  AccountPersonalInformation, 
  AccountClinicInformation, 
  AccountLocationDetails,
  AccountFinancialDetails
} from '../../Components/Account'
import { PrimaryButton, SecondLoader, ChangeEmailAddressModal, ConfirmEmailModal, Text, TextInput } from '../../Components'
import PictureModal from '../../Components/Input/CropImageInput/PictureModal'
import { useAuth, useSubscription } from '../../Global'
import { Axios } from '../../Config'
import { ErrorToast, SuccessToast } from '../../Components/Notification/Notification'
import TooltipMobileModal from '../../Components/TooltipMobileModal'
import Fade from '@material-ui/core/Fade'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopyIconWhite, Info, info } from '../../Themes/Images'
import useStyles from './Account.styles'
import Activate2FA from '../../Components/Activate2FA'
import ConfirmNumberModal from '../../Components/ConfirmNumberModal'


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: colors.primary,
    maxWidth: 800,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: '0px 3px 6px #00000029'
  },
}))(Tooltip)

function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

const ReferalCode = ({ value, handleOpenModal, isMobile, isMobile2 }) => {
  const classes=useStyles()
  const [isCopied, setIsCopied] = useState(false)
   
  return (
    <div className={classes.referalWrapper}>
      <div className={classes.referalInfo}>
        <div>
          <Text style={{ textAlign: 'start', fontSize: isMobile2 && 20 }} color={colors.primary} weight='bold' size='subnote'>My Referral Code</Text>
        </div>
        <div>
          <HtmlTooltip style={{ cursor: isMobile ? 'pointer' : 'auto' }} TransitionComponent={Fade} TransitionProps={{ timeout: 500 }} 
            onClick={isMobile ? handleOpenModal : null} interactive disableHoverListener={isMobile ? true : false}
            placement='right' arrow title={
              <div className={classes.textTooltip}>
                <Text size='subnote' weight='bold' color={colors.primary}>Invite your colleagues to use Dataplayer.</Text>
                <ul style={{ paddingLeft: 20 }}>
                  <li className={classes.marginBottom}><Text color={colors.primary} size='footnote'>When they upgrade, they can use your referral code to get 1 free month. </Text></li>
                  <li className={classes.marginBottom}><Text color={colors.primary} size='footnote'>Each time your referral code is used, you get 15 to 30 free days too.  </Text></li>
                  <li><Text color={colors.primary} size='footnote'>Share Dataplayer, grow your practice together! </Text></li>
                </ul>
              </div>
            }
          >
            <IconButton aria-label="info">
              <Info />
            </IconButton>
          </HtmlTooltip>
        </div>
      </div>
      <div className={classes.subtext}>
        <Text size='footnote' color='#878787'>Gift 1 extra month and get 15 to 30 free days for you!
        </Text>
      </div>
      <div className={classes.inputButton}>
        <div style={{ minWidth: 115 }}>
          <TextInput
            costumizedInputStyles={classes.inputCode}
            account
            // label="Referral code"
            hiddenLabel={true}
            value={value}
            readOnly={true}
          />
        </div>
        <div className={classes.button} >
          <Tooltip open={isCopied === true} title="Referral code copied to clipboard" placement="top" arrow>
            <CopyToClipboard text={value}
              onCopy={() => {
                setIsCopied(true)
                setTimeout(() => { setIsCopied(false) }, 3000)
              }}>
              <PrimaryButton style={{ paddingInline: 20, minHeight: 40, width: 117 }}>
                <React.Fragment>
                  <CopyIconWhite style={{ width: 22, height: 22, marginRight: 10 }} />
                  <Text size='footnote' color={colors.white} >
                    Copy
                  </Text>
                </React.Fragment>
              </PrimaryButton>
            </CopyToClipboard>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
  
function Account() {
  const classes=useStyles()
  const theme = useTheme()
  const { user, picture } = useAuth()
  const [isLoading,  setIsLoading] = useState(true)
  const [userData,  setUserData] = useState({})
  const [phoneNumber, setPhoneNumber] = useState('')
  const [pictureValues, setPicturesValues] = useState({
    url: picture,
    data: undefined,
  })
  const [changePhotoValues, setChangePhotoValues] = useState({
    isVisible: false,
    success: false
  })
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [is2FA, setis2FA] = useState('false')
  const [isSUbmitting2FA, setiSsSUbmitting2FA] = useState(false)
  const [isVisibleVerifyEmailPin, setIsVisibleVerifyEmailPin] = useState(false)
  const [isErrorConfirmEmail, setErrorConfirmEmail] = useState()
  const [emailVerifySuccess, setEmailVerifySuccess] = useState(false)
  const [changeEmailValues, setChangeEmailValues] = useState({
    isVisible: false,
    success: false
  })

  const [isNumberVerified, setIsNumberVerified] = useState(false)
  const [isVisibleVerifyNumberPin, setIsVisibleVerifyNumberPin] = useState(false)
  const [isErrorConfirmNumber, setErrorConfirmNumber] = useState()
  const [numberVerifySuccess, setNumberVerifySuccess] = useState(false)
  const [changeNumberValues, setChangeNumberValues] = useState({
    isVisible: false,
    success: false
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { is_trialing, plan: globalPlan } = useAuth()
  const { subscription, } = useSubscription()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs'))

  const free = globalPlan === 'free'

  const getUser= async()=>{
    try {
      const { attributes } =  await Auth.currentUserInfo()
      let { data } = await Axios.get('/prod/user')
      let newUserData={ ...attributes, ...data }
      setUserData(newUserData)
      setIsEmailVerified(attributes?.email_verified)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  
  const getUserCognito= async()=>{
    try {
      const userToUpdate =  await Auth.currentAuthenticatedUser()
      setis2FA(userToUpdate?.attributes['custom:enabledMFA'])
      setIsNumberVerified(userToUpdate?.attributes?.phone_number_verified)
      setPhoneNumber(userToUpdate?.attributes?.phone_number)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onActivate2FA = async () => {
    setiSsSUbmitting2FA(true)
    try {
      const userToUpdate =  await Auth.currentAuthenticatedUser()
      await  Auth.updateUserAttributes(userToUpdate, {
        'custom:enabledMFA': 'true',
      })
      toast.custom((t) => (
        <SuccessToast t={t} message='2FA enabled'/>
      ))
      setiSsSUbmitting2FA(false)
    } catch (err) {
      // if (err?.name==='LimitExceededException'){
      //   setErrorConfirmEmail(err.message)
      // }
      setiSsSUbmitting2FA(false)
      toast.custom((t) => (
        <ErrorToast t={t} message='Error!'/>
      ))
      console.log(err)
    }
  }

  const onDisable2FA = async () => {
    setiSsSUbmitting2FA(true)
    try {
      const userToUpdate =  await Auth.currentAuthenticatedUser()
      await  Auth.updateUserAttributes(userToUpdate, {
        'custom:enabledMFA': 'false',
      })
      setiSsSUbmitting2FA(false)
      toast.custom((t) => (
        <SuccessToast t={t} message='2FA disabled'/>
      ))
    } catch (err) {
      // if (err?.name==='LimitExceededException'){
      //   setErrorConfirmEmail(err.message)
      // }
      setiSsSUbmitting2FA(false)
      toast.custom((t) => (
        <ErrorToast t={t} message='Error!'/>
      ))
      console.log(err)
    }
  }

  useEffect(() => {
    getUser()
  }, [emailVerifySuccess])

  useEffect(() => {
    getUserCognito()
  }, [isSUbmitting2FA, numberVerifySuccess])

  const onVerifyEmail = async () => {
    try {
      await Auth.verifyCurrentUserAttribute('email')
      setIsVisibleVerifyEmailPin(true)
    } catch (err) {
      if (err?.name==='LimitExceededException'){
        setErrorConfirmEmail(err.message)
      }
    }
  }

  const onVerifyNumber = async () => {
    try {
      await Auth.verifyCurrentUserAttribute('phone_number')
      setIsVisibleVerifyNumberPin(true)
    } catch (err) {
      if (err?.name==='LimitExceededException'){
        setErrorConfirmNumber(err.message)
      }
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  if (isLoading) {
    return <SecondLoader/>
  }

  let content = ''
  if (!isEmailVerified) {
    content = 'Verifying your email is essential to get all important account communications.'
  } else {
    content = 'To change your email, please make a request and we\'ll update it.'
  }

  return (
    <Grid
      container
      style={{ maxWidth: '100%' }}
    >
      <Formik
        enableReinitialize
        initialValues={{
          fullName: userData?.name|| '',
          email: userData?.email|| '',
          role: userData?.middle_name|| '',
          clinicName: userData?.nickname|| '',
          numberOfEmployees: userData?.locale|| '',
          country: userData?.zoneinfo||'',
          timeZone: userData?.country || '',
          MFA: userData?.email_verified,
          phone_number: phoneNumber?? '',
          // / +'' to parse to strinng
          date: userData?.financial_year_end_date?.day +'' || '',
          month: userData?.financial_year_end_date?.month || '',

        }}
        onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
          try {
            const userToUpdate =  await Auth.currentAuthenticatedUser()
            await  Auth.updateUserAttributes(userToUpdate, {
              name: values?.fullName,
              middle_name: values?.role,
              locale: values?.numberOfEmployees,
              nickname: values?.clinicName,
              zoneinfo: values?.country,
              'custom:enabledMFA': values?.MFA?.toString()?? undefined,
              phone_number: values?.phone_number
            })
            await Axios.post('/prod/user', {
              country: values?.timeZone,
              financial_year_end_date: {
                'month': values.month,
                'day': parseInt(values.date)
              }
            })
            toast.custom((t) => (
              <SuccessToast t={t} message='Your account has been updated successfully!'/>
            ))
            await getUser()
            await getUserCognito()
            setSubmitting(false)
          } catch (err) {
            setErrors({ submit: err.message })
            setSubmitting(false)
            toast.custom((t) => (
              <ErrorToast t={t} message='Something went wrong. Try again!'/>
            ))
          }
        }}
      
      >
        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values, dirty, isValid }) => {
          return (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <div
                className={(is_trialing || isEmpty(subscription) || free ) ? classes.gridContainer3 : classes.gridContainer2}
              >
                <AccountAvatarInfo 
                  user={user}
                  setChangePhotoValues={setChangePhotoValues}
                  value={pictureValues}
                />
                {(is_trialing || isEmpty(subscription) || free  ) ? null : 
                  <>
                    <div className={classes.verticalLine}></div>
                    <ReferalCode value={userData?.referralCode} handleOpenModal={handleOpenModal} isMobile={isMobile} isMobile2={isMobile2}/>
                  </> 
                }
              
              </div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} className="dividerFullWidth" />
              <Grid
                container
                className={classes.gridContainer}
              >
                <Typography
                  color="textPrimary"
                
                  variant="h6"
                >
           Personal Information
                </Typography>
                <AccountPersonalInformation
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  // handleChangeNumber={handleChangeNumber}

                  isEmailVerified={isEmailVerified}
                  changeEmailValues={changeEmailValues}
                  setChangeEmailValues={setChangeEmailValues}
                  isErrorConfirmEmail={isErrorConfirmEmail}
                  onVerifyEmail={onVerifyEmail}

                  isNumberVerified={isNumberVerified}
                  changeNumberValues={changeNumberValues}
                  setChangeNumberValues={setChangeNumberValues}
                  isErrorConfirmNumber={isErrorConfirmNumber}
                  onVerifyNumber={onVerifyNumber}
                  isDirty={dirty}
                  isUndefined={phoneNumber === undefined}
                  // phoneNumber={phoneNumber}

                  isMobile={isMobile}
                />
              </Grid>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 15, marginTop: -5, border: '1px solid #ebebeb',
                padding: '18px 16px', maxWidth: 660 }}>   
                <div style={{ display: 'flex', }}>
                  <img style={{ marginRight: 16 }} width={24} height={24} src={info} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center',  marginLeft: 7, marginTop: 1, }}>
                  <Text size='footnote'>
                    <Text className={classes.note} weight='medium' size='subnote'>Note: </Text> 
                    {content}
                  </Text>
                </div>
              </div>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} className="dividerFullWidth" />
              <Grid
                container
                className={classes.gridContainer}
              >
                <Typography
                  color="textPrimary"
               
                  variant="h6"
                >
           Clinic Information
                </Typography>
                <AccountClinicInformation
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isMobile={isMobile}
                />
              </Grid>

              <Divider style={{ marginBottom: 10 }} className="dividerFullWidth" />

              <Grid
                container
                className={classes.gridContainer}
              >
                <Typography
                  color="textPrimary"
               
                  variant="h6"
                >
           Location
                </Typography>
                <AccountLocationDetails
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                />
              </Grid>
                  
              <Divider style={{ marginBottom: 10 }} className="dividerFullWidth" />

              <Grid
                container
                direction='column'
                className={classes.gridContainer}
              >
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                >
          Fiscal Year
                </Typography>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="subtitle2"
          
                >
        Define the last day of your fiscal year, which will be used to prepare the Fiscal Year Report.
                </Typography>
                <AccountFinancialDetails
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
              
                />
              </Grid>

              <Divider style={{ marginBottom: 10 }} className="dividerFullWidth" />

              <Activate2FA enabled={is2FA === 'true'} onSubmit={is2FA === 'true' ? () => onDisable2FA() : () => onActivate2FA()} isEmailVerified={isEmailVerified} submitting={isSUbmitting2FA} />

              <Divider style={{ marginBottom: 10 }} className="dividerFullWidth" />

              <PrimaryButton
                type='submit'
                disabled={isSubmitting || !dirty || !isValid }
                loading={isSubmitting}
                classes={{
                  root: classes.saveButton,
                }}
                style={{
                  backgroundColor: isSubmitting || !dirty || !isValid  ? colors.disabled : colors.primary,
                  color: colors.white
                }}
              >
              Update
              </PrimaryButton>
            </form>


          )}}
      </Formik>
      <PictureModal
        isVisible={changePhotoValues.isVisible}
        picture={pictureValues?.url} 
        alt={user}
        setValue={setPicturesValues}
        onClose={
          () => setChangePhotoValues(prev => ({
            ...prev,
            isVisible: false,
          })
          )}
      />
      <ChangeEmailAddressModal
        isVisible={changeEmailValues.isVisible}
        cancel={
          () => setChangeEmailValues(prev => ({
            ...prev,
            isVisible: false,
          })
          )}
        onSuccess={
          () => setChangeEmailValues(prev => ({
            success: true,
            isVisible: false,
          })
          )}
      />
      <ConfirmEmailModal
        isVisible={isVisibleVerifyEmailPin}
        username={user}
        cancel={() => setIsVisibleVerifyEmailPin(false)}
        setEmailVerifySuccess={setEmailVerifySuccess}
      />
      <ConfirmNumberModal
        isVisible={isVisibleVerifyNumberPin}
        username={user}
        cancel={() => setIsVisibleVerifyNumberPin(false)}
        setNumberVerifySuccess={setNumberVerifySuccess}
      />
      <TooltipMobileModal
        isVisible={isModalOpen}
        cancel={handleCloseModal}
      />
      
    </Grid>
            
  )
}

export default Account
