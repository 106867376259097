/* eslint-disable camelcase */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import Loading from 'react-lottie'
import { dataLoading, info } from '../../Themes/Images'
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'
import Axios from '../../Config/Axios'
import CloseIcon from '@material-ui/icons/Close'
import { useMenu, useAuth, useSubscription } from '../../Global'
import { Collapse, Hidden, IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import { colors } from '../../Themes'
import '../../App.css'
import { ContentContainer, DiscoundCard, SuccessNotify, Text } from '../../Components'
import SkeletonDashboard from '../../Components/SkeletonDashboard'
import { useStyles, useUpgradeCardStyle } from './Dashboard.styles'
import TabsForDashboard from '../../Components/TabsForDashboard'
import { TabPanel } from '../../Components/TabsForDashboard/TabsForDashboard'
import DashboardPlanModal from '../../Components/DashboardPlanModal'
import useCancellationDate from '../../Utils/CancellationDate'

const LOADING_DATA_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: dataLoading,
  rendererSettings: {
    className: 'loading',
  },
}

function Dashboard() {
  const divRef = useRef()
  const qRef = useRef()
  const classes = useStyles()
  const { data_loading, user, upgradeSucces, setUpgradeSuccess, getUpdatedUser, isLoading: isLoadingUser, dashboard_id, is_trialing, roles, active_dash, cancellationEffectiveDate } = useAuth()
  const { isFullScreen, toggleFullScreen } = useMenu()
  const { refetchSubscription, isLoadingSub, subscription } = useSubscription()
  const [isLoading, setLoading] = useState(true)
  // const [isLoadingQ, setLoading] = useState(true)
  const [isDataLoading, setDataLoading] = useState(false)
  const [isCardShowing, setCardShowing] = useState(true)
  const [tabValue, setTabValue] = useState(0)
  const [openPlan, setOpenPlan] = useState(false)
  const theme = useTheme()
  const [height, setHeight] = useState()

  // const embedQSearchBar = async(url) => {    

  //   const embeddingContext = await createEmbeddingContext({
  //     onChange: (changeEvent, metadata) => {
  //       console.log('Context received a change', changeEvent, metadata)
  //     },
  //   })

  //   const option = { className: 'quicksight-embedding-iframe-qsearch' }

  //   const frameOptions = {
  //     url: url,
  //     container: document?.getElementById('containerDashboard'),
  //     height: '45px',
  //     width: '100%',
  //     className: 'quicksight-embedding-iframe-qsearch',
  //     onChange: (changeEvent, metadata) => {
  //       switch (changeEvent.eventName) {
  //       case 'FRAME_MOUNTED': {
  //         console.log('Do something when the experience frame is mounted.')
  //         break
  //       }
  //       case 'FRAME_LOADED': {
  //         console.log('Do something when the experience frame is loaded.')
  //         break
  //       }
  //       }
  //     },
  //   }

  //   const contentOptions = {
  //     hideTopicName: true, 
  //     allowTopicSelection: false,
  //     showPinboard: false,
  //     onMessage: async (messageEvent, experienceMetadata) => {
  //       switch (messageEvent.eventName) {
  //       case 'Q_SEARCH_OPENED': {
  //         console.log('Do something when Q Search content expanded')
  //         break
  //       }
  //       case 'Q_SEARCH_CLOSED': {
  //         console.log('Do something when Q Search content collapsed')
  //         break
  //       }
  //       case 'Q_SEARCH_SIZE_CHANGED': {
  //         console.log('Do something when Q Search size changed')
  //         break
  //       }
  //       case 'CONTENT_LOADED': {
  //         console.log('Do something when the Q Search is loaded.')
  //         break
  //       }
  //       case 'ERROR_OCCURRED': {
  //         console.log('Do something when the Q Search fails loading.')
  //         break
  //       }
  //       }
  //     }
  //   }
  //   const embeddedDashboardExperience = await embeddingContext.embedQSearchBar(frameOptions, contentOptions)
  // }

  const embedGenerativeQnA = async (url) => {

    const embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent, metadata) => {
        console.log('Context received a change', changeEvent, metadata)
      },
    })

    const frameOptions = {
      url: url,
      container: document.getElementById('containerDashboard'),
      height: '45px',
      width: '100%',
      className: 'quicksight-embedding-iframe-qsearch',
      onChange: (changeEvent, metadata) => {
        switch (changeEvent.eventName) {
          case 'FRAME_MOUNTED': {
            console.log('Do something when the experience frame is mounted.')
            break
          }
          case 'FRAME_LOADED': {
            console.log('Do something when the experience frame is loaded.')
            break
          }
        }
      },
    }

    const contentOptions = {
      panelOptions: {
        panelType: 'FULL',
        title: 'Custom Title',
        showQIcon: false,
      },
      // Uncomment below, if you prefer an experience closer to embedQSearchBar instead of a full panel.
      /*
        panelOptions: {
            panelType: 'SEARCH_BAR',
            focusedHeight: '250px',
            expandedHeight: '500px',
        },
        */
      showTopicName: true,
      showPinboard: false,
      allowTopicSelection: false,
      allowFullscreen: true,
      searchPlaceholderText: 'Custom Search Placeholder',
      onMessage: async (messageEvent, experienceMetadata) => {
        switch (messageEvent.eventName) {
          case 'Q_SEARCH_OPENED': {
            console.log('Do something when SEARCH_BAR type panel is expanded')
            break
          }
          case 'Q_SEARCH_FOCUSED': {
            console.log('Do something when SEARCH_BAR type panel is focused')
            break
          }
          case 'Q_SEARCH_CLOSED': {
            console.log('Do something when SEARCH_BAR type panel is collapsed')
            break
          }
          case 'Q_PANEL_ENTERED_FULLSCREEN': {
            console.log('Do something when the experience enters full screen mode')
            break
          }
          case 'Q_PANEL_EXITED_FULLSCREEN': {
            console.log('Do something when the experience exits full screen mode')
            break
          }
          case 'CONTENT_LOADED': {
            console.log('Do something when the experience is loaded')
            break
          }
          case 'ERROR_OCCURRED': {
            console.log('Do something when an error occurs.')
            break
          }
        }
      }
    }
    const embeddedGenerativeQnExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions)
  }

  const embedDashboard = async (url) => {

    const embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent, metadata) => {
        console.log('Context received a change', changeEvent, metadata)
      },
    })

    const frameOptions = {
      url: url,
      container: document.getElementById('containerDashboard'),
      height: '100%',
      width: '100%',
      // resizeHeightOnSizeChangedEvent: true,
      onChange: (changeEvent, metadata) => {
        switch (changeEvent.eventName) {
          case 'FRAME_MOUNTED': {
            console.log('Do something when the experience frame is mounted.')
            break
          }
          case 'FRAME_LOADED': {
            console.log('Do something when the experience frame is loaded.')
            break
          }
        }
      },
    }

    const contentOptions = {
      toolbarOptions: {
        export: true,
        undoRedo: true,
      },
    }
    //   attributionOptions: {
    //     overlayContent: false,
    //   },
    //   onMessage: async (messageEvent, experienceMetadata) => {
    //     switch (messageEvent.eventName) {
    //     case 'CONTENT_LOADED': {
    //       console.log('All visuals are loaded. The title of the document:', messageEvent.message.title)
    //       break
    //     }
    //     case 'ERROR_OCCURRED': {
    //       console.log('Error occurred while rendering the experience. Error code:', messageEvent.message.errorCode)
    //       break
    //     }
    //     case 'PARAMETERS_CHANGED': {
    //       console.log('Parameters changed. Changed parameters:', messageEvent.message.changedParameters)
    //       break
    //     }
    //     case 'SELECTED_SHEET_CHANGED': {
    //       console.log('Selected sheet changed. Selected sheet:', messageEvent.message.selectedSheet)
    //       break
    //     }
    //     case 'SIZE_CHANGED': {
    //       console.log('Size changed. New dimensions:', messageEvent.message)
    //       break
    //     }
    //     case 'MODAL_OPENED': {
    //       window.scrollTo({
    //         top: 0 // iframe top position
    //       })
    //       break
    //     }
    //     }
    //   },
    // }

    const embeddedDashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions)
  }

  const isMobile = useMediaQuery(theme.breakpoints.down(830))

  const { day, month, year } = useCancellationDate(cancellationEffectiveDate)


  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  const openPlanModal = () => setOpenPlan(true)
  const closePlanModal = () => setOpenPlan(false)

  useEffect(() => {
    getUpdatedUser()
    refetchSubscription()
  }, [])

  // dashboard api
  useEffect(() => {
    if (is_trialing === false) {
      return setLoading(false)
    }
    if (!dashboard_id) {
      setLoading(false)
      setDataLoading(true)
    } else {
      Axios({
        method: 'GET',
        url: `/prod/user/dashboard/${dashboard_id}`,
      })
        .then((response) => {
          embedDashboard(response?.data?.EmbedUrl)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        })
    }
  }, [is_trialing, dashboard_id])


  // q api
  // useEffect(() => {
  //   if (is_trialing===false){
  //     return  setLoading(false)
  //   } 
  //   if (!dashboard_id) {
  //     setLoading(false)
  //     setDataLoading(true)
  //   } else {
  //     Axios({
  //       method: 'GET',
  //       url: `/prod/user/dashboard/q/${dashboard_id}`,
  //     })
  //       .then((response) => {
  //         console.log(response, 'response q')
  //         embedQSearchBar(response?.data?.data?.EmbedUrl)
  //         setLoading(false)
  //       })
  //       .catch((error) => {
  //         setLoading(false)
  //       })
  //   }
  // }, [is_trialing, dashboard_id])


  const Header = () => {
    return (

      <div className={classes.headerDashboard}>
        <div className={classes.subheader1}>
          <Text size='subnote' weight='bold' className="sectionTitle">Dashboard</Text>
        </div>
        {(!isMobile && cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) ? <div className={classes.subheader1}>
          <Text size='footnote'>You will switch to the Free plan on <Text size='footnote' weight='medium'>{day} {month} {year}.</Text></Text>
        </div>
          :
          (isMobile && cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) &&
          <div className={classes.mobileHeader}>
            <Text size='footnote'>Plan change</Text>
            <div style={{ cursor: 'pointer' }} onClick={openPlanModal}>
              <img src={info} height={22} width={22} />
            </div>
          </div>

        }

      </div>
    )
  }

  const UpgradeCard = ({ onClose, isLoading }) => {
    const classes = useUpgradeCardStyle()
    // const history = useHistory()


    // if (isLoading) {
    //   return (
    //     <div className={classes.upgradeCardContrainer}>
    //       <p className={classes.text}>Your upgraded dashboard is being processed. Enjoy the old version while we load all data.</p>
    //     </div>
    //   )
    // }

    return (
      <div className={classes.upgradeCardContrainer}>
        <p className={classes.text}>Your current plan will continue until dd/mm/yyyy.</p>
        {/* <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          classes={{ root: classes.iconButtonRoot }}
          style={{ backgroundColor: 'transparent' }}
          onClick={() => history.push('/upgradeplan')}
        >
          <p className={classes.linkTo}>See upgrade options</p>
          <ArrowForwardIcon classes={{ root: classes.icon }} />
        </IconButton> */}
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
          onClick={onClose}
        >
          <CloseIcon classes={{ root: classes.closeIcon }} />
        </IconButton>
      </div>
    )
  }
  UpgradeCard.defalutProps = {
    isLoading: false
  }

  const renderUpgradeCard = () => {
    if (isLoadingUser || isLoadingSub) {
      return null
    }
    else if (is_trialing) {
      return null
    }
    else if (roles.includes('secondary')) {
      return null
    }
    return (
      <>
        {Object.keys(subscription).length > 0 && !isFullScreen &&
          <Collapse in={isCardShowing}>
            <UpgradeCard onClose={() => setCardShowing(false)} />
          </Collapse>
        }
        {/* {Object.keys(subscription).length === 0 && !data_loading && !isFullScreen &&
            <Collapse in={isCardShowing}>
              <UpgradeCard onClose={() => setCardShowing(false)} />
            </Collapse>
        } */}
      </>
    )

  }

  // if ((isDataLoading|| data_loading) && active_dash === true) {
  //   return (
  //     <>
  //       <div className={classes.loadingContainer}>
  //         {/* {upgradeSucces && <SuccessNotify open={upgradeSucces} setOpen={setUpgradeSuccess} message="You request was sent successfully" />} */}
  //         <div className={classes.loadingDataMessage}>
  //           <p
  //             style={{
  //               fontWeight: 600,
  //               fontSize: 20,
  //               fontFamily: 'Roboto',
  //               marginBottom: 'revert',
  //               color: colors.accent,
  //             }}
  //           >
  //             Welcome {user}!
  //           </p>
  //           <p>
  //          Account active
  //           </p>
  //         </div>
  //         <Loading options={LOADING_DATA_OPTIONS} height={700} />
  //       </div>
  //     </>
  //   )
  // }

  if (isDataLoading || data_loading) {
    return (
      <>
        <div className={classes.loadingContainer}>
          {upgradeSucces && <SuccessNotify open={upgradeSucces} setOpen={setUpgradeSuccess} message="You request was sent successfully" />}
          <div className={classes.loadingDataMessage}>
            <p
              style={{
                fontWeight: 600,
                fontSize: 20,
                fontFamily: 'Roboto',
                marginBottom: 'revert',
                color: colors.accent,
              }}
            >
              Welcome {user}!
            </p>
            <p>
              Thank you for creating the account. <br /> <br /> We are currently connecting with your
              PMS to get all your practice data and prepare them into a dashboard. <br /> <br />
              Depending on how much data you have, this may take up to <span style={{ color: colors.accent, fontWeight: 'bold' }}>6 hours</span>.<br /> <br />
              We&apos;ll notify you by email as soon as it is ready.
            </p>
          </div>
          <Loading options={LOADING_DATA_OPTIONS} height={700} />
        </div>
      </>
    )
  }

  return (
    <>
      {/* {false ? 
        <ContentContainer dashboard
          header={
            <TabsForDashboard
              value={tabValue}
              handleChange={handleChangeTab}
            />
          
          }
        /> 
        : 
        <ContentContainer dashboard
          header={
            <div className={classes.subheader1}>
              <Text size='subnote' weight='bold' className="sectionTitle">Dashboard</Text>
            </div>
      
          } />
       
      } */}
      <ContentContainer dashboard
        header={
          // <div className={classes.subheader1}>
          //   <Text size='subnote' weight='bold' className="sectionTitle">Dashboard</Text>
          // </div>
          <Header />

        } />
      {/* <ContentContainer dashboard
        header={
          <TabsForDashboard
            value={tabValue}
            handleChange={handleChangeTab}
          />
          
        }
      />  */}

      {/* {renderUpgradeCard()} */}

      {/* <TabPanel value={tabValue} index={0}> */}
      {isLoading ? (
        <SkeletonDashboard />
      ) : (
        <div
          className={
            isFullScreen ? classes.dashboardFullScreen : classes.dashboard
          }
        >
          {/* If sub exist && data is not loading && is not fullscreen then show card upgrade card else if user has a subscription and data is loading show the info card when data is loading */}

          <Hidden xsDown>
            <IconButton
              disableFocusRipple
              centerRipple={true}
              classes={{
                root: isFullScreen
                  ? classes.iconButtonFull
                  : classes.iconButton,
              }}
              onClick={toggleFullScreen}
            >
              {isFullScreen ? (
                <FullscreenExitIcon classes={{ root: classes.rootBackIcon }} />
              ) : (
                <FullscreenIcon classes={{ root: classes.rootBackIcon }} />
              )}
            </IconButton>
          </Hidden>
          {/* <div ref={qRef} id='qContainer' style={{ zIndex: 100, width: '100%' }}/> */}
          <div ref={divRef} id='containerDashboard' style={{ flex: 2 }} ></div>
        </div>
      )}
      <DashboardPlanModal isVisible={openPlan} cancel={closePlanModal} />
      {/* </TabPanel> */}
      {/* <TabPanel value={tabValue} index={1}>
        Hello
      </TabPanel> */}


    </>
  )
}

export default Dashboard