import React, { useEffect, useReducer, useRef, useState } from 'react'
import useStyles from './AIAssistant,.styles'
import { BadgeAvatar, PrimaryButton, Text, TextInput } from '../../Components'
import {
  ArrowSend, ChatLogoAI, DPGray, ErrorDP, LogoIcon, Plus, PlusBlue, QuestionMark,
  RedInfo, ReportProblemIcon, loading, ChatListIcon,
  ChatHelp, ChatFeedback, SparkleBig, SparkleSmall, Info,
  ThreeDots, ChatLogoError,
  ZoomImg,
  NoChatsLeftIcon,
  ErrorMsg
} from '../../Themes/Images'
import { colors } from '../../Themes'
import { useAuth, useMenu } from '../../Global'
import { useChat } from '../../Global/Chat'
import { Auth } from 'aws-amplify'
// import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IconButton, Tooltip, useMediaQuery, useTheme, withStyles } from '@material-ui/core'
import MarkdownViewer from '../../Components/MarkdownToHTML'
import TypingEffect from '../../Components/TypingEffect'
import AIFeedbackModal from './components/AIFeedbackModal.js'
import { useChatMenu } from '../../Global/ChatHistoryMenu'
import ExplanationModal from './components/ExplanationModal.js'
import Modal1 from '../../Components/Modal/Modal'
import SucessModal from '../../Components/SuccessModal/SuccessModal'
import ZoomImageModal from './components/ZoomImageModal.js'
import AIReportModal from './components/AIReportModal.js'
import { LoadingWords, chatBoxSuggestions, SuggestionWords } from '../AI-Assistant/data/index.js'
import { AlwaysScrollToBottom } from './utils/AlwaysScrollToBottom.js'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js'


const Header = ({ classes, list, chatLeft, noChatsLeft, createNewSession, loading, isSteaming, loadingQuestion }) => (
  <div className={classes.header}>
    <div className={classes.title}>
      <Text weight='bold' size='subnote'>AI Assistant</Text>
    </div>
    {!loading &&
      <div className={classes.requests}>
        <Text size='footnote'>{chatLeft} {chatLeft === 1 ? 'question' : 'questions'} left</Text>
        {list?.length > 0 &&
          <div style={{ marginLeft: 24 }}>
            <PrimaryButton disabled={noChatsLeft || loadingQuestion || isSteaming} style={{
              backgroundColor: noChatsLeft || loadingQuestion || isSteaming ? colors.disabled : colors.primary,
              color: colors.white,
            }} className={classes.newChat} onClick={createNewSession}><Plus style={{ marginRight: 8 }} />New chat</PrimaryButton>
          </div>
        }
      </div>
    }
  </div>
)

const HeaderMobile = ({ classes, list, chatLeft, noChatsLeft, createNewSession, loading, isSteaming, loadingQuestion }) => {

  const [isOpenMenu, setIsOpenMenu] = useState()
  const handleMenuOpen = () => {
    setIsOpenMenu(true)
  }
  const handleMenuClose = () => {
    setIsOpenMenu(false)
  }

  return (
    <div className={classes.header}>
      <div className={classes.logoAndTitle}>
        <ChatLogoAI className={classes.logo} />
        <div className={classes.titleMobileHeader}>
          <Text weight='bold' size='subnote'>AI Assistant</Text>
          {!loading && <Text size='footnote'>{chatLeft} {chatLeft === 1 ? 'question' : 'questions'} left</Text>}
        </div>
      </div>
      <div className={classes.requestsMobileHeader}>
        {(list?.length > 0 && !noChatsLeft) && !loadingQuestion &&
          <PlusBlue onClick={!isSteaming ? createNewSession : null} style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }} />
        }
        {/* <ThreeDots onClick={handleMenuOpen} /> */}
      </div>
      {/* {isOpenMenu && <ChatHistoryMobile isOpenMenu={isOpenMenu} onCancel={handleMenuClose} />} */}
    </div>
  )
}

const WelcomeChat = ({ classes, chatLeft }) => {
  const history = useHistory()
  const [randomNumberIndex, setRadomNumberIndex] = useState([])
  useEffect(() => {
    function generateRandomNumbers(min, max, count) {
      let numbers = []
      while (numbers.length < count) {
        let random = Math.floor(Math.random() * (max - min) + min)
        if (!numbers.includes(random)) {
          numbers.push(random)
        }
      }
      setRadomNumberIndex(numbers)
    }
    generateRandomNumbers(0, chatBoxSuggestions.length, 3)
  }, [])
  const { setMessage, sendMessage, loadingSession } = useChat()
  const handleChatSuggestion = (value) => {
    // console.log('🚀 ~ handleChatSuggestion ~ value:', value)
    if (!loadingSession) {
      setMessage(value)
    }
  }
  const onUpgrade = () => {
    history.push('/upgradeplan')
  }
  let lister = [1, 2, 3]
  return (
    <div className={chatLeft <= 0 || chatLeft === null ? classes.noChatschatContainer : classes.chatContainer}>
      {chatLeft > 0 ?
        <>
          <div className={classes.welcomeText}>
            <div className={classes.aiUser}>
              <SparkleBig />
              <Text weight='bold' size='h5' color='primary'>Hello, {Auth.user.username} </Text>
            </div>
            <Text weight='regular' size='h5' >What information would you like to know about your practice?</Text>
          </div>
          <div className={classes.suggestedBox}>
            {lister.map((item, index) => (
              <div className={classes.chatSuggestions} key={index} onClick={() => handleChatSuggestion(chatBoxSuggestions[randomNumberIndex[index]])}>
                <div></div>
                <Text size='small' color='note'>
                  {chatBoxSuggestions[randomNumberIndex[index]]}
                </Text>
                <div className={classes.suggestionIcon}>
                  <SparkleSmall />
                </div>
              </div>
            ))}
          </div>
        </>
        :
        <div className={classes.noChatsLeftMessage}>
          <NoChatsLeftIcon />
          <div className={classes.middleText}>
            <Text size='subnote'>You have reached the limit of free questions!</Text>
          </div>
          <div className={classes.middleText}>
            <PrimaryButton className={classes.newChat} onClick={onUpgrade}>Upgrade now</PrimaryButton>
          </div>
        </div>
      }
    </div>
  )
}

const SentMessage = ({ classes, message, user, picture, time, isMobile, isLast, index }) => {
  const { messageList } = useChat()
  const elementRef = useRef()
  useEffect(() => {
    if (index === messageList.length - 2 && elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [index, messageList])


  return (

    <>
      <div className={classes.sentMessageContainer} ref={elementRef}>
        <div className={classes.sentMessage}>
          <Text size='footnote'>
            {message}
          </Text>
        </div>
        {!isMobile && <div className={classes.avatar}><BadgeAvatar invisible={true} alt={user} picture={picture} chat /></div>}
      </div>
      <div className={classes.timeSent}>
        <Text size='smaller' color={colors.disabled}>{time}</Text>
      </div>
    </>
  )
}

const LoadingAnimation = ({ classes }) => {

  return (
    <div className={classes.loading}>
      <div className={classes.avatar}><ChatLogoAI className={classes.logo} /></div>
      <div className={classes.receivedMessageTyping}>
        <TypingEffect />
      </div>
    </div>
  )
}


const ErrorMessage = ({ errorServiceMessage, classes }) =>
  <div className={classes.loading}>
    <div className={classes.avatar}><ChatLogoError className={classes.logo} /></div>
    <div style={{ marginLeft: 16 }} className={classes.errorService}>
      <Text size='footnote'>{errorServiceMessage}</Text>
    </div>
  </div>

const ReceivedMessage = ({ classes, receivedMessage, img, time, isMobile, questionId, messageKey, index, loading }) => {
  const [isOpen, setIsOpen] = useState()
  const { isStreaming, finishTyping, messageList } = useChat()
  const [isZoomed, setIsZoomed] = useState(false)
  const elementRef = useRef()

  useEffect(() => {
    const getLatestIndexWithKeyAi = (data) => {
      let latestIndex = -1
      if (data[data.length - 1].key !== 'aiquote') {

        for (let i = data.length - 1; i >= 0; i--) {
          if (data[i].key === 'ai') {
            latestIndex = i
            break
          }
        }
      }
      return latestIndex
    }

    const latestIndex = getLatestIndexWithKeyAi(messageList)

    if (index === latestIndex) {
      if (elementRef.current) {

        elementRef.current.scrollIntoView({ behavior: 'smooth' })

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList])

  const handleExplanationClick = () => {
    setIsOpen(true)
  }
  const handleExplanationClose = () => {
    setIsOpen(false)
  }
  const handleImageZoom = () => {
    setIsZoomed(true)
  }
  const handleImageClose = () => {
    setIsZoomed(false)
  }
  const { sentMessageCount } = useChat()

  return (
    <>
      <div className={classes.receivedMessageContainer}>
        <div className={classes.avatar}><ChatLogoAI className={classes.logo} /></div>
        <div style={{ marginLeft: isMobile ? 16 : 20 }} className={classes.receivedMessage}>
          {(messageKey === 'ai' && finishTyping === false) &&
            <div className={classes.detailsIcon} onClick={isStreaming ? null : handleExplanationClick} ref={elementRef} >
              <Info style={{ width: 20, height: 20 }} />
              {/* <Text size='small'>Details</Text> */}
            </div>
          }
          {isOpen && <ExplanationModal isOpen={isOpen} onCancel={handleExplanationClose} questionId={questionId} loading={loading} />}
          <MarkdownViewer markdownText={receivedMessage} textKey={messageKey} index={index} />

        </div>
      </div>
      {(img === '' || img === null) && <div className={classes.timeReceived}>
        <Text size='smaller' color={colors.disabled}>{time}</Text>
      </div>}
      {
        img && !finishTyping && (
          <div className={classes.imageContainer}>
            <div className={classes.avatar}>
              <ChatLogoAI className={classes.logo} />
            </div>
            <div className={classes.image} onClick={handleImageZoom}>
              <img src={img} className={classes.img} alt="Image" />
              <div className={classes.imgZoom} onClick={handleImageZoom} >
                <ZoomImg />
              </div>
            </div>
            {isZoomed && (
              <ZoomImageModal
                isZoomed={isZoomed}
                onClose={handleImageClose}
                image_url={img}
              />
            )}
          </div>
        )
      }
      {img &&
        <div className={sentMessageCount >= 3 ? classes.timeReceivedWithNote : classes.timeReceived}>
          <Text size='smaller' color={colors.disabled}>{time}</Text>
        </div>
      }
    </>
  )
}
const ReceivedErrorMessage = ({ classes, receivedMessage = 'HIiii', img, time, isMobile, questionId, messageKey, index, loading }) => {
  const { sentMessageCount } = useChat()

  return (
    <>
      <div className={classes.receivedMessageContainer}>
        <div className={classes.avatar}><ErrorMsg className={classes.logo} /></div>
        <div style={{ marginLeft: isMobile ? 16 : 20 }} className={classes.receivedErrorMessage}>
          <MarkdownViewer markdownText={receivedMessage} textKey={messageKey} index={index} />
        </div>
      </div>
      <div className={sentMessageCount >= 3 ? classes.timeReceivedWithNote : classes.timeReceived}>
        <Text size='smaller' color={colors.disabled}>{time}</Text>
      </div>

    </>
  )
}
const ReceivedSuggestedMessage = ({ classes, receivedMessage, isMobile, questionId, messageKey, suggestion1, suggestion2 }) => {

  const [random, setRandom] = useState(Math.floor(Math.random() * SuggestionWords.length))
  const { isStreaming } = useChat()
  const { setMessage, loadingSession } = useChat()
  const handleChatSuggestion = (value) => {
    // console.log('🚀 ~ handleChatSuggestion ~ value:', value) 
    if (!loadingSession) {
      setMessage(value)
    }
  }

  return (
    <>
      <div className={classes.receivedMessageContainer}>
        <div className={classes.avatarSugg}><ChatLogoAI className={classes.logo} /></div>
        <div style={{ marginLeft: isMobile ? 16 : 20 }} className={classes.receivedMessage}>
          <Text size='footnote'>{SuggestionWords[random]}</Text>
        </div>
      </div>
      <div className={classes.suggestedMessageBox}>
        <div className={classes.chatSuggestionsMsg} style={{ flex: 1 }} onClick={() => !isStreaming && handleChatSuggestion(suggestion1)}>
          <div></div>
          <Text size='small' color='note'>
            {suggestion1}
            {/* What is the revnue of 2024 for each practitioner? */}
          </Text>
          <div className={classes.suggestionIcon}>
            <SparkleSmall />
          </div>
        </div>
        <div className={classes.chatSuggestionsMsg} style={{ flex: 1 }} onClick={() => !isStreaming && handleChatSuggestion(suggestion2)}>
          <div></div>
          <Text size='small' color='note'>
            {suggestion2}
            {/* What is the revnue of 2024 for each practitioner? */}
          </Text>
          <div className={classes.suggestionIcon}>
            <SparkleSmall />
          </div>
        </div>
      </div >
      <div style={{ height: '20px' }}></div>
    </>
  )
}

const Messages = ({ classes, loading, user, picture, messages, errorService, errorServiceMessage, isMobile, newestMessageIndex, word }) => {
  // console.log('🚀 ~ Messages ~ messages:', messages)

  const { loadingWord, loadingImage, sentMessageCount } = useChat()

  return (
    messages?.map((message, index) => {
      return (
        <div key={index} className={classes.messagesContainer}>
          {message?.key === 'human' &&
            <>
              <SentMessage
                key={index}
                index={index}
                classes={classes}
                user={user}
                picture={picture}
                isLast={index === messages.length - 1}
                message={message?.message}
                time={message?.time}
                isMobile={isMobile} />

            </>
          }
          {message?.key === 'aiquote' && message?.message === ''
            ? <LoadingAnimation classes={classes} isMobile={isMobile} />
            : message?.key === 'aiquote' &&
            <>
              <ReceivedMessage
                classes={classes}
                index={index}
                key={index + 1}
                receivedMessage={message?.message}
                img={message?.image_url}
                time={message?.time}
                errorService={errorService}
                isMobile={isMobile}
                questionId={message?.id}
                messageKey={message?.key}
              />
              <div style={{ height: '10px' }}></div>
            </>
          }
          {message?.key === 'ai' &&
            <ReceivedMessage
              classes={classes}
              key={index + 2}
              index={index}
              receivedMessage={message?.message}
              img={message?.image_url}
              time={message?.time}
              errorService={errorService} i
              sMobile={isMobile}
              questionId={message?.id}
              messageKey={message?.key}
            />
          }
          {message?.key === 'suggestion' &&
            <ReceivedSuggestedMessage
              classes={classes}
              key={index + 2}
              receivedMessage={message?.message}
              errorService={errorService} i
              sMobile={isMobile}
              questionId={message?.id}
              messageKey={message?.key}
              suggestion1={message?.suggestionOne}
              suggestion2={message?.suggestionTwo}
            />
          }
          {message?.key === 'error' &&
            <ReceivedErrorMessage
              classes={classes}
              key={index + 3}
              receivedMessage={message?.message}
              errorService={errorService} i
              sMobile={isMobile}
              questionId={message?.id}
              messageKey={message?.key}
            />
          }
          {index === messages.length - 1 && loadingImage &&
            <>
              <LoadingAnimation classes={classes} isMobile={isMobile} />
              <div style={{ height: '10px' }}></div>
            </>
          }
          {index === messages.length - 1 && errorService && message?.key !== 'human' && message?.key !== 'aiquote' && <ErrorMessage classes={classes} errorServiceMessage={errorServiceMessage} />}
          {index === messages.length - 1 && sentMessageCount === 3 && <div style={{ height: 70 }}></div>}

        </div>
      )
    })
  )
}

const Chat = ({ classes, success, handleChange, message, disabled, loading, onSendMessage, user, picture, messages, noChatsLeft, chatLeft, onUpgrade,
  errorService, errorServiceMessage, isMobile, loadingSession, newestMessageIndex, inputDisabled, word }) => {

  // console.log('🚀 ~ chatLeft:', chatLeft)

  const { sentMessageCount, isStreaming } = useChat()

  return (
    <div className={classes.chat}>
      {success ?
        <div id='chatSection' className={window.innerHeight < 580 ? classes.overflowChatSmallestMobile :
          window.innerHeight < 670 ? classes.overflowChatSmallerMobile : window.innerHeight < 740 ? classes.overflowChatSmallMobile : classes.overflowChat}>
          <Messages classes={classes} messages={messages} loading={loading} user={user} picture={picture} errorService={errorService}
            errorServiceMessage={errorServiceMessage} isMobile={isMobile} newestMessageIndex={newestMessageIndex} word={word} />
        </div>
        :
        <WelcomeChat classes={classes} chatLeft={chatLeft} onUpgrade={onUpgrade} />
      }

      <div className={sentMessageCount === 3 ? classes.bottomContainerWithNote : classes.bottomContainer}>
        {sentMessageCount === 3 &&
          <div className={classes.chatNote}>
            <Text weight='bold' size='footnote'>NOTE: </Text>
            <Text size='footnote'>To ask about different metrics, start a new chat for more accurate responses. </Text>
          </div>
        }
        <div className={classes.inputHolder}>
          <div className={classes.input}>
            <TextInput
              name='message'
              label='Type your question...'
              labelsize='footnote'
              value={message}
              costumizedInputStyles={classes.inputCustom}
              disabled={loadingSession || loading || noChatsLeft || inputDisabled || isStreaming}
              // onBlur={handleBlur}
              inputLabelStyle={{ color: 'red' }}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && message?.trim() !== '')
                  onSendMessage()
              }}
            />
          </div>
          <div className={classes.sendMessage}>
            <PrimaryButton
              className={classes.button}
              onClick={onSendMessage}
              disabled={disabled || loadingSession || loading || noChatsLeft}
              style={{
                backgroundColor: disabled || loading || loadingSession || noChatsLeft ? colors.disabled : colors.primary,
                color: colors.white,
              }}
            >
              <ArrowSend />
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}


// const ChatHistory = () => {
//   const classes = useStyles()
//   const [isOpen, setIsOpen] = useState(false)
//   const [isReportOpen, setIsReportOpen] = useState()

//   const handleFeedbackClick = () => {
//     setIsOpen(true)
//   }
//   const handleFeedbackClose = () => {
//     setIsOpen(false)
//   }
//   const handleReportClick = () => {
//     setIsReportOpen(true)
//   }
//   const handleReportClose = () => {
//     setIsReportOpen(false)
//   }

//   return (
//     <div className={classes.historyContainer}>
//       <div className={classes.firstChatPart}>
//         <div className={classes.yourChats}>
//           <ChatListIcon />
//           <Text />Your Chats
//         </div>
//         <div className={classes.chatsList}>
//           <div className={classes.currentChat}>
//             <Text size='footnote'>Lorem Ipsum test...</Text>
//             <Text size='smaller'>27/03/2024</Text>
//           </div>
//         </div>
//       </div>
//       <div className={classes.feedback} >
//         <div className={classes.reportProblem} onClick={handleReportClick}>
//           <ReportProblemIcon />
//           <Text size='footnote'>Report a problem</Text>
//         </div>
//         {isReportOpen && <AIReportModal isOpen={isReportOpen} onCancel={handleReportClose} />}
//         <div className={classes.reportProblem}>
//           <ChatHelp />
//           <Text size='footnote'>Help</Text>
//         </div>
//         <div className={classes.reportProblem} onClick={handleFeedbackClick}>
//           <ChatFeedback />
//           <Text size='footnote'>Give feedback</Text>
//         </div>
//         {isOpen && <AIFeedbackModal isOpen={isOpen} onCancel={handleFeedbackClose} />}
//       </div>
//     </div>
//   )
// }

// const ChatHistoryMobile = ({ isOpenMenu, onCancel }) => {
//   const classes = useStyles()
//   const [isOpen, setIsOpen] = useState(false)
//   const [value, setValue] = useState('')
//   const [isDisabled, setDisabled] = useState(true)
//   const [success, setSuccess] = useState(false)
//   const [error, setError] = useState()
//   const [isLoading, setLoading] = useState(false)
//   const [isReportOpen, setIsReportOpen] = useState()

//   const handleFeedbackClick = () => {
//     setIsOpen(true)
//   }
//   const handleFeedbackClose = () => {
//     setIsOpen(false)
//   }
//   const handleClose = () => {
//     setSuccess(false)
//     setValue('')
//     setError()
//     setDisabled(true)
//     setLoading(false)
//     onCancel()
//   }
//   const handleReportClick = () => {
//     setIsReportOpen(true)
//   }
//   const handleReportClose = () => {
//     setIsReportOpen(false)
//   }


//   const mobileChatMenu = (
//     <div className={classes.historyContainerMobile}>
//       <div className={classes.feedbackMobile}>
//         <div className={classes.reportProblemMobile}>
//           <ChatListIcon style={{ width: 20, height: 20 }} />
//           <Text size='footnote'>Your Chats</Text>
//         </div>
//         <div className={classes.reportProblemMobile} onClick={handleReportClick}>
//           <ReportProblemIcon />
//           <Text size='footnote'>Report a problem</Text>
//         </div>
//         {isReportOpen && <AIReportModal isOpen={isReportOpen} onCancel={handleReportClose} />}
//         <div className={classes.reportProblemMobile}>
//           <ChatHelp />
//           <Text size='footnote'>Help</Text>
//         </div>
//         <div className={classes.reportProblemMobile} onClick={handleFeedbackClick}>
//           <ChatFeedback />
//           <Text size='footnote'>Give feedback</Text>
//         </div>
//         {isOpen && <AIFeedbackModal isOpen={isOpen} onCancel={handleFeedbackClose} />}
//       </div>
//     </div>
//   )
//   return (
//     <Modal1 opened={isOpenMenu} onClose={handleClose} type="noBlur" variant='chat' costumizedPaperStyles={success ? classes.paperCostum2 : classes.paperCostum}>
//       {success ? <SucessModal isSmart={false} onCancel={onCancel} /> :
//         mobileChatMenu}

//     </Modal1>
//   )
// }
const AIAssistant = () => {
  const classes = useStyles()
  // const history = useHistory()
  const { message,
    disabled,
    setDisabled,
    inputDisabled,
    loadingSession,
    setLoadingSession,
    loadingWordIndex,
    setLoadingWordIndex,
    diffInMs,
    onUpgrade,
    onError,
    emptyInput,
    handleMessageChange,
    sendMessage,
    createChatSession,
    messageList,
    chatsLeftBackend,
    errorService,
    errorServiceMessage,
    showTyping,
    newestMessageIndex, isStreaming } = useChat()

  const { user, picture } = useAuth()
  const success = messageList?.length > 0

  const noChatsLeft = chatsLeftBackend === 0
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(760))
  useEffect(() => {

    createChatSession(emptyInput, setLoadingSession, onError)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const changeWord = () => {
      // Calculate the duration of each part of the API call
      const firstPartDuration = diffInMs / 3 // 1/3 of the API call duration
      const secondPartDuration = (diffInMs - firstPartDuration) / 2 // Remaining duration divided by 2
      const thirdPartDuration = diffInMs - firstPartDuration - secondPartDuration // Remaining duration

      // Show the first loader for 1/3 of the API call time
      setTimeout(() => {
        setLoadingWordIndex(1)
      }, firstPartDuration)

      // Show the second loader for the next 1/2 of the time
      setTimeout(() => {
        setLoadingWordIndex(2)
      }, firstPartDuration + secondPartDuration)

      // Show the third loader for the remaining time
      setTimeout(() => {
        setLoadingWordIndex(3)
      }, firstPartDuration + secondPartDuration + thirdPartDuration)
    }

    if (showTyping) {
      changeWord()
    }
  }, [showTyping, diffInMs, setLoadingWordIndex]) // Run this effect whenever isLoading changes


  useEffect(() => {
    if (
      message?.trim() !== ''
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }

  }, [message, setDisabled])

  // if (aiAssistantEnabled !== true ) {
  //   return <Redirect push to="/dashboard"/>
  // }



  const HeaderCHat = ({ loading, loadingQuestion }) => (
    window.innerWidth < 760
      ?
      <HeaderMobile loading={loading} isSteaming={isStreaming} loadingQuestion={loadingQuestion} classes={classes} chatLeft={chatsLeftBackend} list={messageList}
        noChatsLeft={noChatsLeft} createNewSession={() => createChatSession(emptyInput, setLoadingSession, onError)} />
      :
      <Header loading={loading} isSteaming={isStreaming} loadingQuestion={loadingQuestion} classes={classes} chatLeft={chatsLeftBackend} list={messageList}
        noChatsLeft={noChatsLeft} createNewSession={() => createChatSession(emptyInput, setLoadingSession, onError)} />
  )

  return (
    <div key={window.location.pathname} className={classes.mainContainer}>
      <div className={classes.container}>
        <div></div>
        <HeaderCHat loading={loadingSession} loadingQuestion={showTyping} />
        <Chat classes={classes} success={success} message={message} handleChange={(e) => handleMessageChange(e)}
          disabled={disabled} loading={showTyping} onSendMessage={(e) => sendMessage(e)}
          user={user} picture={picture} messages={messageList} chatLeft={chatsLeftBackend} noChatsLeft={noChatsLeft}
          onUpgrade={onUpgrade} errorService={errorService} errorServiceMessage={errorServiceMessage} isMobile={isMobile} loadingSession={loadingSession}
          newestMessageIndex={newestMessageIndex} inputDisabled={inputDisabled} word={LoadingWords[loadingWordIndex]}
        />
        <div className={classes.note}>
          <Text size='small' color='note'>Keep in mind that the answers might not always be accurate, so it is good to double-check important details.</Text>
          {/* <Text size='small'><a style={{ color: colors.note }} href='https://www.youtube.com/' target='_blank' rel="noreferrer">Read more here.</a></Text> */}
        </div>
      </div>
      {/* <ChatHistory />  */}
    </div>
  )
}

export default AIAssistant