import React from 'react'
import clsx from 'clsx'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import { FilledInput } from '@material-ui/core'
import Text from '../../Text'
import { textInputStyles, textInputStyles2, useStylesTextInput } from './TextInput.styles'

const Input = withStyles(textInputStyles)((props) => <FilledInput {...props} />)

const InputAccount = withStyles(textInputStyles2)((props) => <FilledInput {...props} />)

function TextInput({
  label,
  value,
  disabled,
  setValue,
  costumizedInputStyles,
  costumizedRootStyles,
  error,
  hiddenLabel,
  onChange,
  inputLabelStyle,
  readOnly,
  account,
  style,
  labelsize = 'subnote',
  ...rest
}) {
  const classes = useStylesTextInput()

  const handleChange = (event) => {
    if (onChange){
      onChange(event)
    } else {
      setValue(event.target.value)
    }
  }

  return (
    <div className={clsx(classes.root, costumizedRootStyles)}>
      <FormControl
        className={clsx(classes.input, costumizedInputStyles)}
        variant="filled"
        hiddenLabel={hiddenLabel}
      >
        { !hiddenLabel && 
          <InputLabel
            style={inputLabelStyle}
            htmlFor="input"
            className={disabled ? 'passwordLabelDisabled' : 'passwordLabel'}
            classes={style}
          >
            {<Text size={labelsize} color='hsl(0, 0%, 50%)'>{label}</Text>}
          </InputLabel>}
        {account ?
          <InputAccount
            id="textInput"
            type="text"
            value={value || ''}
            autoComplete="on"
            disableUnderline={error}
            onChange={handleChange}
            disabled={disabled}
            readOnly={readOnly}
            {...rest}
          /> : 
          <Input
            id="textInput"
            type="text"
            value={value || ''}
            autoComplete="on"
            disableUnderline={error}
            onChange={handleChange}
            disabled={disabled}
            readOnly={readOnly}
            {...rest}
          /> }
      </FormControl>
    </div>
  )
}

TextInput.defaultProps = {
  disabled: false,
  error: false,
  hiddenLabel: false
}

export default TextInput
