import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  datePickerWrapper: {
    display: 'flex',
    position: 'relative',
  },
  calendarIcon: {
    position: 'absolute',
    left: 18,
    top: 9
  },
  datePickerStartInputContainer: {
    position: 'relative',
    padding: 15,
    backgroundColor: colors.background,
    border: '1px solid' + colors.background,
    textAlign: 'center',
    borderRadius: 5,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    color: 'grey',
    '&:focus': {
      backgroundColor: colors.background,
      border: '1px solid' + colors.background,
      borderRadius: 5,
    },
    '&:focus-visible': {
      outline: 'none'
    },
  },
  datePickerEndInputContainer: {
    padding: 15,
    backgroundColor: colors.background,
    border: '1px solid' + colors.background,
    textAlign: 'center',
    borderRadius: 5,
    color: 'grey',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    '&:focus': {
      backgroundColor: colors.background,
      border: '1px solid' + colors.background,
      borderRadius: 5,
    },
    '&:focus-visible': {
      outline: 'none'
    },
  },
}))


const useSimpleDatePickerStyles= makeStyles((theme) => ({
  datePickerWrapper: {
    display: 'flex',
    position: 'relative',
    marginTop: 12,
    marginBottom: 25
  },
  calendarIcon: {
    position: 'absolute',
    left: 18,
    top: 9
  },
  datePickerInputContainer: {
    position: 'relative',
    padding: 15,
    backgroundColor: colors.background,
    border: '1px solid' + colors.background,
    textAlign: 'center',
    borderRadius: 10,
    color: 'grey',
    '&:focus': {
      backgroundColor: colors.background,
      border: '1px solid' + colors.background,
      borderRadius: 10,
    },
    '&:focus-visible': {
      outline: 'none'
    },
  },
}))

export { useStyles, useSimpleDatePickerStyles }