import React, { useState } from 'react'
import { Text, TextInput } from '../../../Components'
import { makeStyles } from '@material-ui/core'
import { SecondaryOutlinedButton3 } from '../../../Components/Button/Button'
import { AmazingIcon, BadIcon, FeedbackAILogo, OkayIcon } from '../../../Themes/Images'
import { IconButton, Modal } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import Modal1 from '../../../Components/Modal/Modal'
import SucessModal from '../../../Components/SuccessModal/SuccessModal'
import { colors } from '../../../Themes'

const useStyles = makeStyles((theme) => ({
    subModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        paddingBottom: 16,
        borderBottom: '1px solid #ebebeb'
    },
    ModalWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        position: 'relative',
        paddingRight: 20,
        paddingLeft: 20,
        // root: {
        //     maxWidth: 484
        // }
    },
    buttonsWrapper: {
        display: 'flex',
        borderTop: '1px solid #ebebeb',
        paddingTop: 24,
        gap: 10,
        marginTop: 16,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    firstPart: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        marginBlock: 16
    },
    input: {
        marginTop: 8,
        marginBottom: 0,
        fontSize: 14
    },
    cancelButton: {
        width: '50%',
        backgroundColor: colors.white,
        border: '1px solid #cccccc',
        height: 50,
        color: colors.primary,
        '&:hover': {
            backgroundColor: colors.grayHover,
            color: colors.white,
            border: `1px solid ${colors.grayHover}`,
        },
    },
    nextButton: {
        width: '50%',
        height: 50,
    },
}
))

function AIReportModal({ isOpen, onCancel }) {
    const classes = useStyles()
    const [value, setValue] = useState('')
    const [isDisabled, setDisabled] = useState(true)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState()
    const [isLoading, setLoading] = useState(false)

    const handleClose = () => {
        setSuccess(false)
        setValue('')
        setError()
        setDisabled(true)
        setLoading(false)
        onCancel()
    }

    const reportModal = (
        <div className={classes.ModalWrapper}>
            <div className={classes.subModal}>
                <div></div>
                <div className={classes.text}>
                    <Text size='h5' color={colors.purple} weight='bold' className={classes.spaceTittle} >Request Customisation</Text>
                </div>
                <IconButton
                    disableFocusRipple
                    centerRipple={true}
                    disableRipple={true}
                    style={{ backgroundColor: 'transparent' }}
                    classes={{ root: classes.iconButtonClose }}
                    onClick={() => onCancel()}
                >
                    <ClearIcon classes={{ root: classes.iconButtonClose }} />
                </IconButton>
            </div>
            <div className={classes.firstPart}>
                <Text size='subnote' className={classes.spaceTittle} >Is there anything bothering you? Help us get better by reporting what you think should be improved.</Text>
                <TextInput
                    name='comment'
                    costumizedInputStyles={classes.input}
                    label='More is less'
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    multiline={true}
                    rows={6}
                />
            </div>
            <div className={classes.buttonsWrapper}>
                <SecondaryOutlinedButton3
                    className={classes.cancelButton}
                    onClick={handleClose}
                >
                    Cancel
                </SecondaryOutlinedButton3>
                <SecondaryOutlinedButton3
                    className={classes.nextButton}
                    disabled={isDisabled || isLoading}
                    loading={isLoading}
                    onClick={() => {

                        setLoading(true)
                        setTimeout(() => {
                            setLoading(false)
                            onCancel()
                        }, 2000)
                    }}
                >
                    Report
                </SecondaryOutlinedButton3>
            </div>
        </div>
    )
    return (
        <Modal1 opened={isOpen} onClose={handleClose} type="noBlur" costumizedPaperStyles={success ? classes.paperCostum2 : classes.paperCostum} >
            {success ? <SucessModal isSmart={false} onCancel={onCancel} /> :
                reportModal}

        </Modal1>
    )
}

export default AIReportModal
