import React from 'react'
import {  TextInput } from '../..'
import {  SelectInput } from '../../Input'
import {  Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { selectStyles2, selectStyles3 } from '../AccountFinancialDetails/AccountFinancialDetails.styles'
import useStyles4 from './AccountLocationDetails.styles'

const SELECT_OPTIONS = [
  { value: 'Australia', label: 'Sydney NSW, Australia (GMT+11)' },
  { value: 'Canada', label: 'Ottawa, ON, Canada (GMT-5)' },
  { value: 'United Kingdom', label: 'London, UK (GMT)' },
]

function AccountLocationDetails(
  { 
    values,
    handleChange,
    handleBlur,
    setFieldValue,
  }) {
  const classes=useStyles4()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChangeSelect=(value)=>{
    setFieldValue('timeZone', value.value)
  }

  return (
    <Grid
      item
      lg={12}
      md={12}
      xl={12}
      xs={12}
    >
      <Grid
        container
        spacing={isMobile ? 0 : 2}
      >
        <Grid
          item
          xl={4}
          lg={4}
          md={6}
          xs={12}>
          <TextInput
            costumizedInputStyles={classes.input}
            name="country"
            label='Location'
            value={values.country}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xl={4}
          lg={4}
          md={6}
          xs={12}>
          <SelectInput
            value={ SELECT_OPTIONS ? SELECT_OPTIONS.find(option => option.value === values.timeZone) : ''}
            selectCostumStyles={isMobile ? selectStyles3 : selectStyles2}
            label='Time Zone'
            placeholder="Time Zone"
            options={SELECT_OPTIONS}
            onChange={handleChangeSelect}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AccountLocationDetails
