import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'
import { maxWidth } from '@material-ui/system'

const useStyles = makeStyles((theme) => ({
    ModalWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        position: 'relative',
        paddingRight: 20,
        paddingLeft: 20,
        // root: {
        //     maxWidth: 484
        // }
    },
    ratingItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #aaa',
        borderRadius: 5,
        gap: 8,
        paddingBlock: 8,
        alignItems: 'center',
        justifyContent: 'center',
        width: 122,
        flexWrap: 'wrap',
        cursor: 'pointer',

    },

    ratings: {
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
    },
    modalAILogo: {
        position: 'absolute',
        top: -44,
        left: 240,
        [theme.breakpoints.down(760)]: {
            left: 138
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down(760)]: {
            marginBottom: 16
        }
    },
    firstPart: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        marginBottom: 16
    },
    input: {
        marginTop: 8,
        marginBottom: 0,
        fontSize: 14
    },
    buttonsWrapper: {
        display: 'flex',
        gap: 10,
        marginTop: 24,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },
    cancelButton: {
        width: '50%',
        backgroundColor: colors.white,
        border: '1px solid #cccccc',
        height: 50,
        color: colors.primary,
        '&:hover': {
            backgroundColor: colors.grayHover,
            color: colors.white,
            border: `1px solid ${colors.grayHover}`,
        },
    },
    nextButton: {
        width: '50%',
        height: 50,
    },
}
))

export default useStyles