/* eslint-disable max-depth */
/* eslint-disable line-comment-position */
/* eslint-disable camelcase */
import React, { createContext, useState, useContext, useEffect } from 'react'
import { ErrorToast } from '../Components/Notification/Notification'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosChat } from '../Config'
import { Exception } from 'sass'
import { Auth } from 'aws-amplify'

const ChatContext = createContext()

export const useChat = () => {
  return useContext(ChatContext)
}

export const ChatProvider = ({ children }) => {
  const history = useHistory()
  let now = new Date()
  const url = process.env.REACT_APP_AI_CHAT_PATH

  // State variables 
  const [message, setMessage] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [inputDisabled, setInputDisabled] = useState(false)
  const [loadingSession, setLoadingSession] = useState(false)
  const [isReportProblem, setIsReportProblem] = useState(false)
  const [loadingWordIndex, setLoadingWordIndex] = useState(0)
  const [diffInMs, setDiffInMf] = useState()
  const [isStreaming, setIsStreaming] = useState(false)
  const [hasError, setHasError] = useState(false)
  // Chat Session States
  const [chatId, setChatId] = useState()
  const [newestMessageIndex, setNewestMessageIndex] = useState(0)
  const [messageList, setMessageList] = useState([])
  const [chatsLeftBackend, setChatsLeftBackend] = useState(1)
  const [errorService, setErrorService] = useState()
  const [errorServiceMessage, setErrorServiceMessage] = useState('')

  const [showTyping, setShowTyping] = useState(false)
  const [finishTyping, setFinishTyping] = useState(false)
  const [stopDots, setStopDots] = useState(false)
  // Chat Stream states
  const [isLoading, setIsLoading] = useState(false)


  const [loadingImage, setLoadingImage] = useState(false)
  const [explanation, setExplanation] = useState('')

  const [loadingWord, setLoadingWord] = useState('')
  const [quoteFinished, setQuoteFinished] = useState(false)
  const [sentMessageCount, setSentMessageCount] = useState(0)


  async function getAuth() {
    try {
      const session = await Auth.currentSession()
      // Extract token expiration and refresh token
      const idTokenExpire = session.getIdToken().getExpiration()
      const refreshToken = session.getRefreshToken()
      const currentTimeSeconds = Math.round(+new Date() / 1000)

      // Check if the ID token has expired
      if (idTokenExpire < currentTimeSeconds) {
        // Refresh the session if the token has expired
        const user = await Auth.currentAuthenticatedUser()

        return new Promise((resolve, reject) => {
          user.refreshSession(refreshToken, (err, data) => {
            if (err) {
              Auth.signOut()
              reject(err)  // Reject the promise if there is an error
            } else {
              const dataToken = data.getAccessToken().jwtToken
              return dataToken
            }
          })
        })
      }
      // eslint-disable-next-line no-else-return
      else {

        const sessToken = session.getAccessToken().jwtToken
        return sessToken
      }

    } catch (error) {
      console.log('🚀 ~ getAuth ~ error:', error)
      return Promise.reject(error)
    }
  }


  // Function that creates a new chat session
  const createChatSession = async (firstAction, setLoadingSession, onError) => {
    setLoadingSession(true)
    let timer


    timer = setTimeout(() => {
      console.log('The request is taking longer than expected...')
      // setChatsLeftBackend(0)
      onError()
    }, 10000)

    try {
      firstAction()
      setMessageList([])
      setNewestMessageIndex(0)
      setSentMessageCount(0)
      let data = await AxiosChat.get('/session')
      if (data?.status !== 200 && data?.status !== 400) {
        onError()
        throw new Error('General Error')
      }

      // let history = await AxiosChat.get('/history')
      // console.log('🚀 ~ createChatSession ~ history:', history)


      clearTimeout(timer)


      setChatsLeftBackend(data?.data?.questions_left)
      setChatId(data?.data?.id)
      setLoadingSession(false)
    } catch (error) {

      clearTimeout(timer)
      setLoadingSession(false)
      if (error?.response?.data?.message === 'You\'ve reached the limit of questions you can ask. Please upgrade to ask more questions.') {
        // setChatsLeftBackend(0)
        onError()
      }
    }
  }

  // useEffect(() => { console.log(messageList), [messageList] })

  const askQuestion = async (question, sessionId) => {
    let hour = (now.getHours() < 10 ? '0' : '') + now.getHours()
    let minute = (now.getMinutes() < 10 ? '0' : '') + now.getMinutes()
    const timeOfMessage = `${hour}:${minute}`
    // let authToken = localStorage.getItem('authTokenAi')
    const authToken = await getAuth()

    setIsStreaming(true)
    setStopDots(false)
    let chatResponse = ''
    let waitingQuote = ''
    // let suggestion = ''
    let questionId = ''
    let imageUrl = ''
    let aiQuoteIndex = null
    let error = ''
    let timer
    timer = setTimeout(() => {
      console.log('The request is taking longer than expected...')
      // setChatsLeftBackend(0)
      onError()
    }, 120000)


    let aiMessageIndex = null
    let startResponse = false
    let initialMessageSet = false
    let responseFinished = false

    let aiSuggestionIndex = null
    let startSuggestion = false
    let initialSuggestionSet = false


    let errormessageIndex = null
    let initialErrorSet = false
    let hasResponseError = false


    setMessageList(prevMessageList => {

      const lastSuggestionIndex = prevMessageList
        .map((msg, index) => msg.key === 'suggestion' ? index : -1)
        .filter(index => index !== -1)
        .pop()


      if (lastSuggestionIndex !== undefined && lastSuggestionIndex !== -1) {
        const newMessageList = [...prevMessageList]
        // Remove the last 'suggestion' entry
        newMessageList.splice(lastSuggestionIndex, 1)
        return newMessageList
      }

      return prevMessageList
    })

    setMessageList(prevMessageList => {
      aiQuoteIndex = prevMessageList.length
      return [...prevMessageList, { message: '', key: 'aiquote', id: '', time: '' }]
    })

    const response = await fetch(
      `${url}chat`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', source: 'test', authorization: 'Bearer ' + authToken },
        body: JSON.stringify({
          session_id: sessionId,
          question: question,
        }),
      }
    )

    if (!response.ok) {
      const errorMessage = await response.text()
      setIsStreaming(false)
      setErrorServiceMessage(errorMessage)
      throw new Error(`Network response was not ok: ${errorMessage}`)
    }
    // console.log('🚀 ~ handleStream ~ response:', response)
    const reader = response.body.getReader()
    const decoder = new TextDecoder()
    const loopRunner = true
    setIsLoading(true)
    while (loopRunner) {
      if (window.location.pathname !== '/ai-assistant') {
        break
      }
      const { value, done } = await reader.read()
      if (done) {
        clearTimeout(timer)
        setSentMessageCount(prev => prev + 1)
        setChatsLeftBackend(prev => prev - 1)
        setIsStreaming(false)
        setIsLoading(false)
        break
      }
      const decodedChunk = decoder.decode(value, { stream: true })

      let splitted_response = decodedChunk.split('|<break>|')

      // console.log('🚀 ~ askQuestion ~ splitted_response:', splitted_response)

      splitted_response.map((response) => {

        if (response !== '') {
          const decodedChunkJSON = JSON.parse(response)
          // console.log('🚀 ~ splitted_response.map ~ decodedChunkJSON:', decodedChunkJSON)

          if (decodedChunkJSON['type'] === 'waiting') {
            // Split the waitingQuote into sentences
            waitingQuote += decodedChunkJSON['text']
            const sentences = waitingQuote.split('\n').filter(Boolean)

            // Update the message list with the latest sentence
            if (sentences.length > 0) {
              const latestSentence = sentences[sentences.length - 1] // Get the last sentence
              setMessageList(prevMessageList => {
                const newMessageList = [...prevMessageList]
                newMessageList[aiQuoteIndex].message = latestSentence // Display the latest sentence
                return newMessageList
              })
            }
          }

          if (decodedChunkJSON['type'] === 'error' && !initialErrorSet) {
            setMessageList(prevMessageList => {
              errormessageIndex = prevMessageList.length // Store the index
              initialErrorSet = true
              return [...prevMessageList, { message: '', key: 'error', id: '', image_url: '' }]
            })
            hasResponseError = true
          }

          if (hasResponseError && decodedChunkJSON['type'] === 'error') {
            error += decodedChunkJSON['text']
            setMessageList(prevMessageList => {
              const newMessageList = [...prevMessageList]
              newMessageList[errormessageIndex].message = error || '' // Incrementally update the message
              return newMessageList
            })
          }


          if (decodedChunkJSON['type'] === 'response') {
            startResponse = true
            clearTimeout(timer)
            setStopDots(true)
          }

          if (startResponse && !initialMessageSet) {
            setMessageList(prevMessageList => {
              aiMessageIndex = prevMessageList.length // Store the index
              initialMessageSet = true
              return [...prevMessageList, { message: '', key: 'ai', id: '', image_url: '' }]
            })
          }


          if (decodedChunkJSON['type'] === 'response' && startResponse) {

            chatResponse += decodedChunkJSON['text']
            setMessageList(prevMessageList => {
              const newMessageList = [...prevMessageList]
              newMessageList[aiMessageIndex].message = chatResponse || '' // Incrementally update the message
              newMessageList[aiMessageIndex].time = timeOfMessage
              return newMessageList
            })
            setIsLoading(prev => !prev)
          }



          if (decodedChunkJSON['type'] === 'suggestion') {
            startSuggestion = true
          }

          if (startSuggestion && !initialSuggestionSet) {
            setMessageList(prevMessageList => {
              aiSuggestionIndex = prevMessageList.length // Store the index
              initialSuggestionSet = true
              return [...prevMessageList, { message: '', key: 'suggestion', suggestionOne: '', suggestionTwo: '' }]
            })

          }
          if (decodedChunkJSON['type'] === 'suggestion' && startSuggestion && imageUrl !== '') {

            setMessageList(prevMessageList => {
              const newMessageList = [...prevMessageList]
              const currentMessage = newMessageList[aiSuggestionIndex]

              if (!currentMessage.suggestionOne) {
                currentMessage.suggestionOne = decodedChunkJSON['text']
              } else if (!currentMessage.suggestionTwo) {
                currentMessage.suggestionTwo = decodedChunkJSON['text']
              }

              newMessageList[aiSuggestionIndex] = currentMessage
              return newMessageList
            })

            // Toggle loading state
            setIsLoading(prev => !prev)
          }

          if (decodedChunkJSON['type'] === 'image_generation') {

            setLoadingImage(true)
          }

          if (
            decodedChunkJSON['is_last_response'] === 'yes' &&
            decodedChunkJSON['type'] === 'image'
          ) {
            setLoadingImage(false)
            questionId = decodedChunkJSON['question_id']
            // console.log('🚀 ~ splitted_response.map ~ questionId:', questionId)
            imageUrl = decodedChunkJSON['image_url']
            // console.log('🚀 ~ splitted_response.map ~ imageUrl:', imageUrl)
            setMessageList(prevMessageList => {
              const newMessageList = [...prevMessageList]
              newMessageList[aiMessageIndex].image_url = imageUrl
              newMessageList[aiMessageIndex].id = questionId
              return newMessageList
            })

          }
        }
      })
    }

  }
  const explainQuestion = async (questionId) => {
    const authToken = await getAuth()

    setExplanation('')
    let accumulatedText = ''

    try {
      const response = await fetch(
        `${url}explain/${questionId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', source: 'test', authorization: 'Bearer ' + authToken }
        }
      )

      if (!response.ok || !response.body) {
        throw new Error(response.statusText)
      }

      const reader = response.body.getReader()
      const decoder = new TextDecoder()

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read()

        if (done) {
          break
        }

        const decodedChunk = decoder.decode(value, { stream: true })
        const splittedResponse = decodedChunk.split('|<break>|')

        for (const part of splittedResponse) {
          if (part.trim() !== '') {
            // eslint-disable-next-line max-depth
            try {
              const decodedChunkJSON = JSON.parse(part)
              if (decodedChunk['text'] !== '') {


                accumulatedText += decodedChunkJSON['text']
                setExplanation((prev) => {
                  const newExplanation = accumulatedText
                  setTimeout(() => {
                    setExplanation(newExplanation)
                  }, 50)
                  return prev
                })
              }
            } catch (err) {
              console.error('Error parsing JSON:', err)
            }
          }
        }
      }
    } catch (error) {
      console.error('Error fetching explanation:', error)
    }
  }


  // Handle report problem modal opening
  const handleOpenReportProblem = () => {
    setIsReportProblem(true)
  }

  // Handle report problem modal closing
  const handleCloseReportModal = () => {
    setIsReportProblem(false)
  }



  // Navigate to upgrade plan page
  const onUpgrade = () => {
    history.push('/upgradeplan')


  }

  // Error handler
  const onError = () => {
    toast.custom((t) => (
      <ErrorToast t={t} message='We appreciate your patience while we dig deeper for you.' />
    ))
    setInputDisabled(true)
    setDisabled(true)
  }

  // Clear the input message 
  const emptyInput = () => setMessage('')

  // Function to push a message to the message list
  const pushFunction = async (messagesSent) => {
    await messageList.push(messagesSent)
  }

  // Hanlde message input change
  const handleMessageChange = (e) => {
    setMessage(e.target.value)
  }


  let hour = (now.getHours() < 10 ? '0' : '') + now.getHours()
  let minute = (now.getMinutes() < 10 ? '0' : '') + now.getMinutes()
  const timeOfMessage = `${hour}:${minute}`
  // Question object for the chat
  let questionAsked = { message: message, key: 'human', time: timeOfMessage }


  const sendMessage = async (msg) => {
    let questionTemp = { message: msg, key: 'human', time: timeOfMessage }
    setMessage('')
    await pushFunction(message === '' ? questionTemp : questionAsked)
    const date1 = new Date()
    if (!loadingSession) {

      await askQuestion(message === '' ? msg : message, chatId)
    }
    const date2 = new Date()
    const diffInMs = date2 - date1
    setDiffInMf(diffInMs)
  }

  return (
    <ChatContext.Provider value={{
      message,
      setMessage,
      disabled,
      setDisabled,
      inputDisabled,
      setInputDisabled,
      loadingSession,
      setLoadingSession,
      isReportProblem,
      setIsReportProblem,
      loadingWordIndex,
      setLoadingWordIndex,
      setExplanation,
      diffInMs,
      setDiffInMf,
      handleOpenReportProblem,
      handleCloseReportModal,
      onUpgrade,
      onError,
      emptyInput,
      handleMessageChange,
      sendMessage,
      createChatSession,
      messageList,
      chatId,
      askQuestion,
      setMessageList,
      errorService,
      errorServiceMessage,
      showTyping,
      newestMessageIndex,
      chatsLeftBackend,
      loadingWord,
      explainQuestion,
      explanation,
      setShowTyping,
      finishTyping,
      setFinishTyping,
      quoteFinished,
      setQuoteFinished,
      sentMessageCount,
      isLoading,
      isStreaming,
      setIsStreaming,
      loadingImage,
      setLoadingImage,
      stopDots
    }
    }>
      {children}
    </ChatContext.Provider >
  )
}