/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useState, useEffect, useReducer } from 'react'
import Select from 'react-select'
import clsx from 'clsx'
import { List, Divider, IconButton, Hidden, useMediaQuery, useTheme } from '@material-ui/core'
import { Route, Link, Redirect, Switch, withRouter, useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import Axios from '../../Config/Axios'
import { BillNotPaidModal, Drawer, DrawerListItem, DrawerNestedListItem, VerifyEmailCardDrawer, PrimaryButton, TextInput, StepperModal, Text } from '../../Components'
import {
  DashboardIcon,
  LogoutIcon,
  SettingsIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  logoIcon,
  logoName,
  info,
  TargetIcon,
  Report,
  StoreWrapper,
  HelpCenter,
  Xero,
  StoreIcon,
  ReportsIcon,
  HelpIcon,
  uploadFile,
  HalaxyUpload,
  UploadFile,
  AIICon
} from '../../Themes/Images'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { colors } from '../../Themes'
import { useAuth, useMenu, useSubscription } from '../../Global'
import { useWindowDimensions } from '../../Utils'

import Settings from '../Settings/Settings'
import Dashboard from '../Dashboard/Dashboard'
import Tooltips from '../Tooltips/Tooltips'

import NotFound from '../NotFound/NotFound'
import UpgradeSubscription from '../UpgradeSubscription/UpgradeSubscription'
import UpgradePlan from '../UpgradePlan/UpgradePlan'
import GigaAccountEndedModal from '../../Components/GigaAccountEndedModal/GigaAccountEndedModal'
import TrialDaysCard from '../../Components/TrialDaysCard/TrialDaysCard'
import CostumerFeedbackInput from '../../Components/CustomerFeedbackInput/CostumerFeedbackInput'
import CostumerModalFeedback from '../../Components/CostumerModalFeedback/CostumerModalFeedback'
import Store from '../Addons/Store'
import StoreProductDetails from '../Addons/StoreProductDetails'
import { useStyles, selectStyles } from './Wrapper.styles'
import { Auth } from 'aws-amplify'
import ReferalCard from '../../Components/ReferalCard'
import InactiveToActiveModal from '../../Components/InactiveToActiveModal'
import XERODashboard from '../XERODashboard'
import DeletePlan from '../DeletePlan'
import { DrawerNestedListItemDashboards } from '../../Components/Drawer/Drawer'
import MultipleDashboard from '../MultipleDashboards'
import AIAssistant from '../AI-Assistant'

const DRAWER_FILES = [
  {
    title: 'Halaxy upload',
    Icon: HalaxyUpload,
    to: '/settings/myFiles',
    component: Link,
  },
]

const DRAWER_UPLOAD_FILE = [
  {
    title: 'Upload files',
    Icon: UploadFile,
    to: '/settings/uploadFile',
    component: Link,
  },
]

const DRAWER_OTHER_ITEMS2 = [
  {
    title: 'Store',
    Icon: StoreIcon,
    to: '/store',
    component: Link,
  },
]

const DRAWER_OTHER_ITEMS = [
  {
    title: 'Reports',
    Icon: ReportsIcon,
    to: '/settings/report',
    component: Link,
  },
]

const DRAWER_ITEMS = [
  {
    title: 'Targets',
    Icon: TargetIcon,
    to: '/settings/targets',
    component: Link,
  },

]

const dashboardItem = [
  {
    title: 'Dashboard',
    Icon: DashboardIcon,
    to: '/dashboard',
    component: Link,
  },
]

const aiAssistantItem = [
  {
    title: 'AI Assistant',
    Icon: AIICon,
    to: '/ai-assistant',
    component: Link,
  },
]

const dashboardItemProve = [
  {
    title: 'Dashboardsssssss',
    Icon: DashboardIcon,
    to: '/dashboard',
    component: Link,
  },
]

const xeroDashboardItems = [
  {
    title: 'XERO Dashboard',
    Icon: Xero,
    to: '/xero',
    component: Link,
  },
]

const DRAWER_ITEMS_MOBILE = [
  {
    title: 'Settings',
    Icon: () => <SettingsIcon style={{ width: 22, height: 22, marginLeft: 15 }} />,
    to: '/settings',
    component: Link,
  },

]


const SELECT_OPTIONS = [
  { value: 'Australia', label: 'Sydney NSW, Australia (GMT+11)' },
  { value: 'Canada', label: 'Ottawa, ON, Canada (GMT-5)' },
  { value: 'United Kingdom', label: 'London, UK (GMT)' },
]

const MARKETING_OPTIONS = [
  { value: 'Search Engine (Google, Bing etc.)', label: 'Search Engine (Google, Bing etc.)' },
  { value: 'Recommended by a friend or colleague', label: 'Recommended by a friend or colleague' },
  { value: 'Listed as integration on Cliniko/Nookal', label: 'Listed as integration on Cliniko/Nookal' },
  { value: 'Our social media', label: 'Our social media' },
  { value: 'Communities/Groups', label: 'Communities/Groups' },
  { value: 'Articles', label: 'Articles' },
  { value: 'Other', label: 'Other' },
]

const ConfirmSignupModal = ({ getUpdatedUser, onSuccessSave }) => {
  const classes = useStyles()
  const { logout, userAttributes } = useAuth()
  const [modalValues, setModalValues] = useState({
    location: '',
    apiKey: '',
    foundedFrom: null,
    foundedByOther: null,
    phoneNumber: '+355697213755',
    errors: {},
  })
  const [disabled, setDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    if (
      modalValues?.location !== '' &&
      modalValues?.apiKey !== '' &&
      Object.keys(modalValues?.errors).length === 0
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [modalValues.location, modalValues.apiKey, modalValues?.errors])

  const handleChangeSelect = (selectedOption, key) => {
    setModalValues((prev) => ({
      ...prev,
      [key]: selectedOption,
    }))
  }

  const handleChangeApiKey = (value) => {
    setModalValues((prev) => ({
      ...prev,
      apiKey: value,
      errors: {},
    }))
  }

  const onSave = () => {
    setIsLoading(true)
    const data = {}
    data.apikey = modalValues?.apiKey
    data.country = modalValues?.location?.value
    data.phoneNumber = modalValues?.phoneNumber

    if (modalValues?.foundedFrom?.value !== 'Other' && modalValues?.foundedFrom !== null) {
      data.marketing_source = modalValues?.foundedFrom?.value
    }

    if (modalValues?.foundedFrom?.value === 'Other') {
      if (modalValues?.foundedByOther?.trim()?.length > 0) {
        data.marketing_source = modalValues?.foundedByOther
      }
    }

    Axios.post(
      '/prod/user/trial',
      { ...data }
    )
      .then((r) => {
        onSuccessSave()
        // eslint-disable-next-line promise/no-nesting
        getUpdatedUser().then((res) => {
          setIsLoading(false)
        }).catch(e => console.log(e))

      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response) {
          setModalValues((prev) => ({
            ...prev,
            errors: { apiKey: error?.response?.data?.message },
          }))
        }
      })
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    // <Modal costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
    <div className={classes.ModalWrapper}>
      {/* <p className={classes.firstTitle}>Hi, welcome to Dataplayer!</p>
        <p className={classes.helperMessage}>
          {' '}
          Before you get started, we just need a few more details.
        </p> */}
      <div className={classes.inputContainer}>
        <p
          style={{
            fontFamily: 'Roboto',
            fontWeight: '400',
          }}
        >
          PMS API Key
        </p>

        <TextInput
          costumizedInputStyles={
            classes['input_' + Boolean(modalValues?.errors?.apiKey)]
          }

          error={Boolean(modalValues?.errors?.apiKey)}
          value={modalValues?.apiKey}
          setValue={handleChangeApiKey}
          name="apikey"
          label="API Key"
          inputLabelStyle={{ fontSize: 16 }}
        />
        {modalValues?.errors?.apiKey && (
          <p className={classes.errorInput}>{modalValues?.errors?.apiKey}</p>
        )}
        {userAttributes?.given_name === 'Cliniko' && <div
          style={{
            padding: 10,
            border: `1px solid ${colors.disabled}`,
            borderRadius: 5,
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
          }}
          onClick={() =>
            openInNewTab(
              'https://help.cliniko.com/en/articles/1023957-generate-a-cliniko-api-key'
            )
          }
        >
          <img
            style={{
              height: 20,
              width: 21,
              marginRight: 13,
            }}
            src={info}
            alt="Nana"
          />
          <p
            style={{
              fontFamily: 'Roboto',
              fontWeight: 400,
            }}
          >
            Click here to learn how to generate your {userAttributes?.given_name} API Key.
          </p>
        </div>}
        {userAttributes?.given_name === 'Nookal' && <div
          style={{
            padding: 10,
            border: `1px solid ${colors.disabled}`,
            borderRadius: 5,
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
          }}
          onClick={() =>
            openInNewTab(
              'https://www.nookal.com/help/connecting-nookal-to-dataplayer'
            )
          }
        >
          <img
            style={{
              height: 20,
              width: 21,
              marginRight: 13,
            }}
            src={info}
            alt="Nana"
          />
          <p
            style={{
              fontFamily: 'Roboto',
              fontWeight: 400,
            }}
          >
            Click here to learn how to generate your {userAttributes?.given_name} API Key.
          </p>
        </div>}
        <p
          style={{
            fontFamily: 'Roboto',
            fontWeight: '400',
            marginTop: 20,
            marginBottom: 8
          }}
        >
          Timezone
        </p>
        <Select
          styles={selectStyles}
          label={<Text size='subnote' color='hsl(0, 0%, 50%)'>Location</Text>}
          placeholder="Timezone"
          value={modalValues?.location}
          onChange={(e) => { handleChangeSelect(e, 'location') }}
          options={SELECT_OPTIONS}
          inputLabelStyle={{ fontSize: 16 }}

        />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 8 }}>
          <p
            style={{
              fontFamily: 'Roboto',
              fontWeight: '400',
              display: 'block'
            }}
          >
            How did you discover Dataplayer?
          </p>
          <p style={{
            display: 'block',
            marginLeft: 8,
            color: '#999999'
          }} >
            {'(optional)'}
          </p>
        </div>
        <div className={classes.marketingInput}>
          <Select
            styles={selectStyles}
            label={<Text size='subnote' color='hsl(0, 0%, 50%)'>Location</Text>}
            placeholder="Select an option"
            value={modalValues?.foundedFrom}
            onChange={(e) => { handleChangeSelect(e, 'foundedFrom') }}
            options={MARKETING_OPTIONS}
          />
          {modalValues?.foundedFrom?.value === 'Other' &&
            <TextInput
              costumizedRootStyles={
                classes.otherInput
              }
              costumizedInputStyles={
                classes.specifyReason
              }
              error={Boolean(modalValues?.errors?.apiKey)}
              value={modalValues?.foundedByOther}
              setValue={(e) => {
                if (e?.length < 100) {
                  handleChangeSelect(e, 'foundedByOther')
                }
              }}
              name="apikey"
              label="Please Specify"
            />}
        </div>
      </div>
      <div className={classes.divider} />
      <div className={classes.buttonsWrapper}>
        <PrimaryButton
          className={classes.cancelButton}
          onClick={logout}
        >Log out
        </PrimaryButton>
        <PrimaryButton
          className={classes.nextButton}
          loading={isLoading}
          disabled={disabled || isLoading}
          style={
            disabled || isLoading
              ? {
                backgroundColor: colors.disabled,
                color: colors.white,
                height: 57
              }
              : {
                backgroundColor: colors.primary,
                height: 57
              }
          }
          type="submit"
          onClick={onSave}
        >
          Continue
        </PrimaryButton>

      </div>


    </div>
    // </Modal>
  )
}

function MiniDrawer() {
  const classes = useStyles()
  const history = useHistory()
  const { dashboard_id } = useParams()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(760))
  const {
    logout,
    userAttributes,
    website_locked,
    end_of_trial_date,
    is_trialing,
    showFreeTrialModal,
    data_loading,
    active_dash,
    account_state,
    plan2,
    subscription_id,
    xeroDashboard,
    given_name,
    fileUploadActive,
    additional_dashboards,
    getUpdatedUser,
    createChatSession,
    chatsLeft,
    setMessageList,
    aiAssistant
  } = useAuth()
  const { subscription, plan } = useSubscription()
  const { width } = useWindowDimensions()
  const {
    selectedItem,
    isVisible,
    isExpanded,
    isFullScreen,
    toggleDrawer,
    toggleDrawerExpansion,
    setSelectedRoute,
    toggleDrawerOnHelpCenterExpansion
  } = useMenu()
  const [isEmailVerified, setEmailVerified] = useState(false)
  const [costumerFeedbackState, setCostumerFeedbackState] = useState({
    show: false,
    isModalOpen: false
  })
  const [userData, setUserData] = useState({})
  const [steps, setSteps] = useState({
    current: 0,
    elements: [ConfirmSignupModal],
    isVisible: false,
  })

  const getUser = async () => {
    try {
      const { attributes } = await Auth.currentUserInfo()
      let newUserData = { ...attributes }
      setUserData(newUserData)
    } catch (error) {
      console.log(error)
    }
  }

  // const profitWell = window?.profitwell('user_id', subscription_id )

  useEffect(() => {
    getUser()
    // profitWell
  }, [])


  const onCloseCostumerFeedback = () => {
    setCostumerFeedbackState((prev) => (
      {
        ...prev,
        show: false
      }
    ))
  }
  const onOpenCostumerFeedbackModal = () => {
    setCostumerFeedbackState((prev) => (
      {
        ...prev,
        isModalOpen: true
      }
    ))
  }
  const onCloseCostumerFeedbackModal = () => {
    setCostumerFeedbackState((prev) => (
      {
        show: false,
        isModalOpen: false
      }
    ))
  }

  const current = window.location.pathname
  const getGreetingTime = (currentTime) => {
    if (!currentTime || !currentTime.isValid()) {
      return 'Hello'
    }
    const splitAfternoon = 12
    const splitEvening = 17
    const currentHour = parseFloat(currentTime.format('HH'))

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      return 'Good afternoon'
    } else if (currentHour >= splitEvening) {
      return 'Good evening'
    }
    return 'Good morning'
  }

  useEffect(() => {
    // when user has given a api key but he hasnt yet completed the middle name
    // if (!website_locked) { /* && !userAttributes?.hasOwnProperty('middle_name') */
    //   setSteps(prev => ({
    //     current: 1,
    //     elements: [...prev?.elements],
    //     isVisible: true
    //   }))
    // }
    if (website_locked) {
      setSteps(prev => ({
        current: 0,
        elements: [...prev?.elements],
        isVisible: true
      }))
    }
  }, [website_locked, userAttributes?.hasOwnProperty('middle_name')])

  useEffect(() => {
    if (localStorage.getItem('feedback')) {
      let date = localStorage.getItem('feedback')
      let start = moment(date)
      let end = moment().format('YYYY-MM-DD')
      // Difference in number of days
      let days = moment.duration(start.diff(end)).asDays()

      if (days <= -12) {
        setCostumerFeedbackState((prev) => (
          {
            show: true,
            isModalOpen: false
          }
        ))
        localStorage.setItem('feedback', JSON.stringify(moment().format('YYYY-MM-DD')))
      }
    } else {
      localStorage.setItem('feedback', JSON.stringify(moment().format('YYYY-MM-DD')))
      setCostumerFeedbackState((prev) => (
        {
          show: true,
          isModalOpen: false
        }
      ))
    }
  }, [])
  useEffect(() => {
    setSelectedRoute(current)
  }, [current])

  useEffect(() => {
    if (userAttributes?.email_verified) {
      setEmailVerified(true)
    } else {
      setEmailVerified(false)
    }

  }, [userAttributes])

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
    let d = document
    let s = d.createElement('script')
    s.type = 'text/javascript'
    s.id = 'zsiqscript'
    s.defer = true
    s.src = 'https://salesiq.zoho.eu/widget'
    let t = d.getElementById('zohoChat')
    t.parentNode.insertBefore(s, t)
  }

  const greeting = getGreetingTime(moment())

  const handleOpenAdditional = () => {
    setOpen(!open)
    toggleDrawerOnHelpCenterExpansion()
  }

  const noAdditionalDashboards = additional_dashboards?.length === 0

  const newCHat = () => {
    if (chatsLeft === 0) {
      setMessageList([])
      toggleDrawer()
    }
    else {
      toggleDrawer()
    }
  }

  return (
    <Switch>
      <Route
        path={[
          '/dashboard',
          '/dashboard/:dashboard_id',
          '/xero',
          '/tooltips',
          '/settings',
          '/settings/account',
          '/settings/delete-account',
          '/settings/change-password',
          '/settings/terms&conditions',
          '/settings/targets',
          '/settings/payment-information',
          // '/enter-data',
          '/settings/report',
          '/settings/unsubscribe/:productId',
          '/store',
          '/ai-assistant'

        ]}
      >
        <div className={classes.root}>
          <div className={isFullScreen ? 'drawerWrapper' : ''}>
            <Hidden xsDown>
              <IconButton
                style={{ position: 'fixed' }}
                disableFocusRipple
                centerRipple={true}
                onClick={toggleDrawerExpansion}
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: isExpanded,
                  [classes.drawerClose]: !isExpanded,
                })}
              >
                {isExpanded ? (
                  <ArrowLeftIcon fill="#ee4352" className="toggleDrawerIcon" />
                ) : (
                  <ArrowRightIcon fill="#ee4352" className="toggleDrawerIcon" />
                )}
              </IconButton>
            </Hidden>
            <Drawer
              onOpen={toggleDrawer}
              onClose={toggleDrawer}
              variant={width > 760 ? 'permanent' : 'temporary'}
              isExpanded={isExpanded}
              isVisible={isVisible}
            >

              <Hidden smUp>
                <div className="logoContainer">


                  <div style={{ display: 'flex' }}>
                    <img src={logoIcon} className="logoIcon" onClick={() => {
                      history.push('/dashboard')
                      toggleDrawer()
                    }} />
                    {(isExpanded || width <= 760) && (
                      <img
                        src={logoName}
                        className="logoName"
                        onClick={() => {
                          history.push('/dashboard')
                          toggleDrawer()
                        }}
                      />
                    )}
                  </div>
                  <IconButton
                    disableFocusRipple
                    centerRipple={true}
                    classes={{ root: classes.iconButton }}
                    onClick={toggleDrawer}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </div>
              </Hidden>
              <div className="drawerContent">
                <div>
                  <Divider className="divider" variant="middle" />
                  <List>
                    {noAdditionalDashboards && dashboardItem.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}

                    {!noAdditionalDashboards &&
                      <DrawerNestedListItemDashboards
                        index={14}
                        key={14}
                        title="Dashboards"
                        Icon={DashboardIcon}
                        isActive={open}
                        withTitle={isExpanded || width <= 760}
                        onClick={() =>
                          handleOpenAdditional()
                        }
                        open={open}
                      //  items={additional_dashboards}
                      />
                    }

                    {xeroDashboard && xeroDashboardItems.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    {DRAWER_ITEMS.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    {fileUploadActive && DRAWER_UPLOAD_FILE.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    {given_name === 'Halaxy' && DRAWER_FILES.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    {given_name !== 'Halaxy' && DRAWER_OTHER_ITEMS.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    {DRAWER_OTHER_ITEMS2.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    <DrawerNestedListItem
                      index={4}
                      key={4}
                      title="Help Centre"
                      Icon={HelpIcon}
                      // isActive={open}
                      withTitle={isExpanded || width <= 760}
                      onClick={() =>
                        window.open(
                          'https://dataplayerhelp.atlassian.net/servicedesk/customer/portals',
                          '_blank'
                        )
                      }
                    // open={open}
                    />
                    {isMobile && DRAWER_ITEMS_MOBILE.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}

                      />
                    ))}
                    <DrawerNestedListItem
                      index={5}
                      key={5}
                      title="Chat with us"
                      Icon={() => <ChatBubbleIcon style={{ width: 22, height: 22, marginLeft: 18, backgroundColor: 'transparent', color: colors.accent }} />}
                      isActive={open}
                      withTitle={isExpanded || width <= 760}
                      onClick={() => handleClick()}
                    // open={open}
                    />
                    {aiAssistant && aiAssistantItem.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={newCHat}
                        component={item.component}
                        to={item.to}
                      />
                    ))}


                  </List>
                </div>
                <div>
                  {(plan?.[0]?.name === 'Mega' || plan?.[0]?.name === 'Giga') && <ReferalCard isExpanded={isExpanded} isMobile={isMobile} onClick={toggleDrawer} />}
                  {!isEmailVerified && isExpanded && current !== '/settings/account' && <VerifyEmailCardDrawer onClose={() => setEmailVerified(true)} />}
                  {is_trialing && <TrialDaysCard isExpanded={isExpanded} end_of_trial_date={end_of_trial_date} />}
                  {isMobile &&
                    <div className={classes.buttonDiv}>
                      <PrimaryButton
                        onClick={() => history.push('/upgradeplan')}
                        className={classes.button}>
                        Upgrade now
                      </PrimaryButton>
                    </div>
                  }

                  <Divider style={{ marginInline: '25px !important' }} className="divider2" variant="middle" />

                  <DrawerListItem
                    index={9999}
                    title="Log out"
                    Icon={LogoutIcon}
                    isActive={true}
                    withTitle={isExpanded || width <= 760}
                    onClick={logout}
                  />
                </div>
              </div>
            </Drawer>
          </div>
          <main className={classes.content}>
            <div className="homeContent">
              <Route exact path="/dashboard" component={Dashboard} />
              {additional_dashboards?.length > 0 && <Route exact path="/dashboard/:dashboard_id" component={MultipleDashboard} />}
              <Route exact path="/xero" component={XERODashboard} />
              {/* <Route exact path="/enter-data" component={ManuallyAddData} /> */}
              <Route exact path="/tooltips" component={Tooltips} />
              <Route exact path="/store" component={Store} />
              <Route exact path="/ai-assistant" component={aiAssistant && AIAssistant} />
              <Route
                exact
                path={[
                  '/settings',
                  '/settings/account',
                  '/settings/myFiles',
                  '/settings/uploadFile',
                  '/settings/delete-account',
                  '/settings/change-password',
                  '/settings/terms&conditions',
                  '/settings/targets',
                  '/settings/payment-information',
                  '/settings/report',
                  '/settings/unsubscribe/:productId'
                ]}
                component={Settings}
              />
            </div>
            {costumerFeedbackState.show
              &&
              <CostumerFeedbackInput
                onClose={onCloseCostumerFeedback}
                onOpenCostumerFeedbackModal={onOpenCostumerFeedbackModal}
              />
            }
          </main>
          <BillNotPaidModal
            isVisible={subscription?.state === 'paused'}
            type={plan?.[0]?.billing_type === 'month' ? 'monthly' : plan?.[0]?.billing_type === 'year' ? 'yearly ' : ''}
            plan={plan?.[0]?.name || ''}
            updatePayment={() => window.open(subscription?.update_url, '_blank')}
          />
          <StepperModal
            isVisible={steps.isVisible}
            current={steps.current}
            elements={steps.elements}
            onSuccessAPI={() => {
              setSteps(prev => ({
                ...prev,
                isVisible: false
                // current: prev.current + 1,
              }))
            }}
          />
          <GigaAccountEndedModal
            isVisible={showFreeTrialModal === true && active_dash === true}
          />
          <InactiveToActiveModal isVisible={account_state === 'inactive' && active_dash === false && plan?.length === 0} />
          <CostumerModalFeedback
            isVisible={costumerFeedbackState.isModalOpen}
            cancel={onCloseCostumerFeedbackModal}
          />
        </div>
      </Route>
      <Route exact path="/storedetails/:productId" component={StoreProductDetails} />
      <Route exact path="/upgradeplan" component={UpgradePlan} />
      <Route exact path="/upgrade" component={UpgradeSubscription} />
      <Route exact path="/cancelPlan" component={DeletePlan} />
      <Redirect to="/dashboard" path={['/', '/reset-password', '/login']} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default withRouter(MiniDrawer)
